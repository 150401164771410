import { StepsContainer } from "../../../modules/mqr/components/steps-container";
import { CompanyProfileForm } from "../../../modules/mqr/templates/company-profile-form";
import { MerchantInformationForm } from "../../../modules/mqr/templates/merchant-information-form";
export const MerchantInformation = () => {
  return (
    <div>
      <div className="font-bold text-2xl text-neutral-600 my-1 md:my-4 flex justify-center">
        MQR
      </div>
      <div className="my-3 md:my-6 text-center max-w-[400px] mx-auto">
        <div className="font-bold text-2xl md:text-4xl text-neutral-900">
          Merchant Director Information
        </div>
        <div className="text-sm md:text-base font-medium text-neutral-600 mt-1">
          We need details about the company director for KYC and regulatory
          compliance
        </div>
      </div>
      <div className="flex my-3 w-full gap-x-[3%] items-start flex-wrap justify-center lg:justify-normal gap-y-4 lg:flex-nowrap">
        <StepsContainer current_step={2} />
        <MerchantInformationForm />
      </div>
    </div>
  );
};
