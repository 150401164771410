import getDate from "../../../../core/hooks/getDate";
import { Mqr } from "../../../../core/types";

export const MqrCard = ({ mqr }: { mqr: Mqr }) => {
  return (
    <div className="flex bg-neutral-100 gap-x-4 xs:gap-x-20 rounded py-3 px-3">
      <div className="flex flex-col gap-y-4">
        <div>
          <div className=" text-neutral-600 text-sm">Company name</div>
          <div className=" text-neutral-900 text-sm xs:text-base">
            {mqr.companyName}
          </div>
        </div>
        <div>
          <div className=" text-neutral-600 text-sm">Created on</div>
          <div className=" text-neutral-900 text-sm xs:text-base">
            {getDate(mqr.created_at)}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-4 md:pr-20">
        <div>
          <div className=" text-neutral-600 text-sm">Status</div>
          <div className=" text-sm xs:text-base text-secondary-500">
            MQR Submitted
          </div>
        </div>
        <div>
          <div className=" text-neutral-600 text-sm">Last update on</div>
          <div className=" text-neutral-900 text-sm xs:text-base">
            {getDate(mqr.updated_at)}
          </div>
        </div>
      </div>
    </div>
  );
};
