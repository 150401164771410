import * as yup from "yup";
export const companyProfileSchema = yup.object().shape({
  companyName: yup.string().required("Required"),
  registrationNumber: yup.string().min(2).required("Required"),
  registeredAddress: yup.string().min(2).required("Required"),
  country: yup.string().min(2).required("Required"),
  phoneNumber: yup.string().min(2).required("Required"),
  CompanySize: yup.string().min(1).required("Required"),
  website: yup.string().min(2).required("Required"),
  country_code: yup.string().required("Required"),
  email: yup.string().email("Please enter a valid email").required("Required"),

  citizenship: yup.string().min(1).required("Required"),
  position: yup.string().min(1).required("Required"),
  category: yup.string().min(1).required("Required"),
  passportNumber: yup.string().min(1).required("Required"),
});
