export const company_size = [
  {
    id: 1,
    title: "1-5",
    value: "1-5",
  },
  {
    id: 2,
    title: "5-25",
    value: "5-25",
  },
  {
    id: 3,
    title: "25-50",
    value: "25-50",
  },
  {
    id: 4,
    title: "50-100",
    value: "50-100",
  },
  {
    id: 5,
    title: "100-500",
    value: "100-500",
  },
  {
    id: 6,
    title: "500-1000",
    value: "500-1000",
  },
  {
    id: 7,
    title: "100+",
    value: "100+",
  },
];
