import { AuthHeader } from "../../modules/auth-header";

export const EmailSent = () => {
  return (
    <div className=" w-full max-w-[1160px] py-3">
      <div className="flex w-full h-[calc(100vh-16px)] ">
        <div className="flex-1 flex flex-col justify-start items-center">
          <AuthHeader />
          <div className=" flex-1 flex w-full gap-y-2 items-center justify-center flex-col">
            <div className=" text-3xl font-bold text-neutral-900">
              Email was sent successfully
            </div>
            <div className=" text-neutral-600 text-base">
              Please check your email to reset your password
            </div>
            <div className="mt-4 w-full sm:w-auto "></div>
          </div>
        </div>
        <div className=" w-[50%] hidden lg:flex justify-center items-center ">
          <img
            src="/bgs/login-bg.png"
            className=" h-full max-h-[800px]"
            alt="login-bg.png"
          />
        </div>
      </div>
    </div>
  );
};
