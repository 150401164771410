import { Step } from "../../../common/step";

export const StepsContainer = ({ current_step }: { current_step: number }) => {
  const Steps = [
    {
      id: 1,
      title: "Company profile",
    },
    // {
    //   id: 2,
    //   title: "Merchant Director Information",
    // },
    {
      id: 3,
      title: "Product Selection",
    },
  ];
  return (
    <div className=" bg-neutral-100  p-3 rounded-xl w-[220px] flex flex-col gap-y-2">
      {Steps.map((step, index) => (
        <Step
          key={step.id}
          title={step.title}
          step_number={index + 1}
          current_step={current_step as number}
        />
      ))}
    </div>
  );
};
