export const categories = [
  {
    id: 1,
    value: "Payment provider",
    title: "Payment provider",
  },
  {
    id: 2,
    value: "Business info",
    title: "Business info",
  },
  {
    id: 3,
    value: "Vendor",
    title: "Vendor",
  },
  {
    id: 4,
    value: "Merchant",
    title: "Merchant",
  },
];
