import { AuthHeader } from "../../modules/auth-header";
import { LoginForm } from "../../modules/login-form";
export const Login = () => {
  return (
    <div className="py-2 px-3 w-full flex justify-center h-[100vh] overflow-y-auto y-global-bg ">
      <div className=" w-full max-w-[1160px]">
        <div className="flex w-full h-[calc(100vh-16px)] ">
          <div className="flex-1 flex flex-col justify-start items-center">
            <AuthHeader />
            <div className=" flex-1 flex w-full gap-y-2 items-center justify-center flex-col">
              <div className=" text-3xl font-bold text-neutral-900">Log in</div>
              <div className=" text-neutral-600 text-base">
                Use your email and password to login
              </div>
              <div className="mt-4 w-full sm:w-auto ">
                <LoginForm />
              </div>
            </div>
          </div>
          <div className=" w-[50%] hidden lg:flex justify-center items-center ">
            {/* <div className="flex bg-gradient-to-b from-blue-50 via-blue-200 to-blue-500 h-full w-auto items-center justify-center p-6 rounded-[19px]">
                <img src="/bgs/login-bg-2.png" className=" max-h-full" alt="" />
            </div> */}
            <img
              src="/bgs/login-bg.png"
              className=" h-full max-h-[800px]"
              alt="login-bg.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
