import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

export const AccountCreated = () => {
  return (
    <div className=" w-full max-w-[1160px] py-3">
      <div className="flex w-full h-[calc(100vh-16px)] justify-between ">
        <div className="flex-1 flex flex-col justify-start items-center">
          <div className=" flex-1 flex w-full gap-y-8 items-center justify-center flex-col">
            <div className=" mt-3 md:mt-6 h-[72px] aspect-square rounded-full bg-green-600 flex items-center justify-center">
              <FaCheck size={34} color="white" />
            </div>
            <div className=" xs:text-3xl text-2xl font-bold text-neutral-600 px-4  xs:px-6 text-center">
              Congratulations, your Account has been successfully created
            </div>
            <Link
              to={"/login"}
              className=" my-2 bg-green-600 rounded-full px-6 py-2 text-white font-bold text-base"
            >
              Continue
            </Link>
            <div className="mt-4 w-full sm:w-auto "></div>
          </div>
        </div>
        <div className=" w-[50%] hidden lg:flex justify-center items-center ">
          <img
            src="/bgs/login-bg.png"
            className=" h-full max-h-[800px]"
            alt="login-bg.png"
          />
        </div>
      </div>
    </div>
  );
};
