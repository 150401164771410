import ReCAPTCHA from "react-google-recaptcha";

export const Recaptcha = () => {
  function onChange(value: any) {
    console.log("Captcha value:", value);
  }
  return (
    <div className=" w-full">
      <ReCAPTCHA
        className=" w-full"
        sitekey="6LeNRSIqAAAAANG5wBBPONo9fznN14C6bXMNbFf9"
        onChange={onChange}
      />
    </div>
  );
};
