import * as yup from "yup";
export const newPasswordSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup.string().min(6).required("Required"),
  password_confirmation: yup
    .string()
    .min(6)
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});
