import { useState } from "react";
import { FaCheck } from "react-icons/fa";

export const FormCheckBox = ({
  title,
  value,
  onClick,
}: {
  title: string;
  onClick?: () => void;
  value: boolean;
}) => {
  const [checked, setChecked] = useState(value);
  // const OnHandleClick = () => {
  //   !value ?
  // }
  return (
    <>
      <button
        type="button"
        onClick={onClick}
        className=" outline-none border-none py-2 flex items-center gap-x-3"
      >
        <div
          className={`${
            value ? "bg-blue-500" : ""
          } w-[14px] h-[14px] rounded border border-blue-400 flex items-center justify-center`}
        >
          {value && <FaCheck color="white" size={10} />}
        </div>
        <div className="text-neutral-900 text-sm font-medium">{title}</div>
      </button>
    </>
  );
};
