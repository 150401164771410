import { FaCheck } from "react-icons/fa6";
import { StepsContainer } from "../../../modules/mqr/components/steps-container";
import { FormButton } from "../../../modules/common";
import { useNavigate } from "react-router-dom";
export const MqrDone = () => {
  const navigate = useNavigate();
  return (
    <div className=" flex items-center justify-start flex-col w-full">
      <div className="font-bold text-2xl text-neutral-600 my-1 md:my-4">
        MQR
      </div>
      <div className=" mt-3 md:mt-6 h-[72px] aspect-square rounded-full bg-green-600 flex items-center justify-center">
        <FaCheck size={34} color="white" />
      </div>
      <div className=" text-neutral-900 text-2xl md:text-4xl md:my-5 my-3 font-bold">
        Thanks
      </div>
      <div className=" text-neutral-600 text-base font-medium text-center">
        Thank you! Your MQR form has been successfully submitted, Our team will
        review your information, and you will be notified of the next steps
        shortly
      </div>
      <div className="my-4">
        <StepsContainer current_step={4} />
      </div>
      <div className=" max-w-[400px] w-full">
        <FormButton
          type={"submit"}
          title="Done"
          isLoding={false}
          disabled={false}
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
};
