import { CiFileOn } from "react-icons/ci";
import { FaCheck, FaRegTrashAlt } from "react-icons/fa";
import { useDeleteFile } from "../../../../services/api";
import { Spinner } from "../../../common";
import { Link } from "react-router-dom";

export const FileCard = ({ docFile, showTrash, refetch, url, title }: any) => {
  const { mutateAsync, isPending, isError, isSuccess } = useDeleteFile();
  const onSubmit = async () => {
    await mutateAsync(docFile.id, {
      onSuccess: (response) => {
        // console.log("response ", response);
        refetch();
      },
      onError: (error) => {
        console.log("from ", error);
      },
    }).catch((error) => console.log());
  };
  return (
    <>
      <div className="p-4 w-full rounded border border-neutral-300">
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-x-2 items-center">
            <CiFileOn size={28} className=" text-neutral-500" />
            <div className=" text-neutral-900 font-semibold text-sm">
              {docFile.name}
            </div>
          </div>
          {docFile.state === "approved" ? (
            <div className=" flex items-center gap-x-1 text-xs text-green-600 font-semibold">
              approved
              <div className="rounded-full h-5 w-5 bg-green-600 flex items-center justify-center ">
                <FaCheck size={15} color="white" />
              </div>
            </div>
          ) : (
            <>
              {isPending ? (
                <Spinner />
              ) : (
                <div
                  className={`${
                    docFile.state === "rejected"
                      ? "text-red-600"
                      : " text-secondary-500"
                  } flex items-center gap-x-1 text-xs  font-semibold`}
                >
                  {docFile.state === "rejected" ? "rejected" : "Pending"}
                  <button onClick={onSubmit}>
                    <FaRegTrashAlt
                      size={22}
                      className={` ${
                        docFile.state === "rejected"
                          ? " text-red-600"
                          : "text-secondary-500"
                      } `}
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="font-bold text-primary-500 text-sm capitalize mt-2">
          {title}
        </div>
        <Link
          target="_blank"
          to={url}
          className=" text-sm text-primary-500 font-bold"
        >
          Click to view
        </Link>
      </div>
    </>
  );
};
