import * as yup from "yup";
export const registerSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email").required("Required"),
  password: yup.string().min(6).required("Required"),
  confirm_password: yup
    .string()
    .min(6)
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
  first_name: yup.string().min(3).required("Required"),
  last_name: yup.string().min(3).required("Required"),
  website: yup.string(),
});
