import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RoutesLayout from "./Routes";
import {
  Login,
  Register,
  Home,
  AccountSetup,
  CompanyProfile,
  MerchantInformation,
  ProductSelection,
  MqrDone,
  KybUploads,
  ForgotPassword,
  NewPassword,
  EmailSent,
  AccountCreated,
} from "../pages";
import APIProvider from "../services/global/APIProvider";
import { useAuth } from "../core/auth";
const Router = () => {
  const { status } = useAuth();
  const router = createBrowserRouter([
    {
      path: "/",
      element:
        status === "signIn" ? <RoutesLayout /> : <Navigate to={"/login"} />,

      children: [
        { path: "/", element: <Home /> },
        // { path: "/account-setup", element: <AccountSetup /> },
        { path: "/account-setup/company-profile", element: <CompanyProfile /> },
        {
          path: "/account-setup/merchant-information",
          element: <MerchantInformation />,
        },
        {
          path: "/account-setup/product-selection",
          element: <ProductSelection />,
        },
        { path: "/account-setup/mqr-thanks", element: <MqrDone /> },
        { path: "/account-setup/kyb-uploads", element: <KybUploads /> },
      ],
    },
    {
      path: "/login",
      element: status !== "signIn" ? <Login /> : <Navigate to={"/"} />,
    },
    {
      path: "/register",
      element: status !== "signIn" ? <Register /> : <Navigate to={"/"} />,
    },
    {
      path: "/forgot-password",
      element: status !== "signIn" ? <ForgotPassword /> : <Navigate to={"/"} />,
    },
    {
      path: "/reset-password",
      element: status !== "signIn" ? <NewPassword /> : <Navigate to={"/"} />,
    },
    {
      path: "/email-sent",
      element: status !== "signIn" ? <EmailSent /> : <Navigate to={"/"} />,
    },
    {
      path: "/account-created",
      element: status !== "signIn" ? <AccountCreated /> : <Navigate to={"/"} />,
    },
  ]);

  return (
    <APIProvider>
      <RouterProvider router={router} />
    </APIProvider>
  );
};
export default Router;
