import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";

const getFiles = async (id: number | undefined) => {
  const { data } = await client.get(`merchant/files/${id}`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data.files;
};

export function useFiles(id: number | undefined) {
  return useQuery({ queryKey: ["get-files"], queryFn: () => getFiles(id) });
}
