import React, { useEffect, useState } from "react";
import Select from "react-select";
// import CreatableSelect from 'react-select/creatable';

export const FormMultiSelect = ({
  handleChange,
  errors,
  touched,
  handleBlur,
  label,
  login,
  defaultValue,
  isMulti,
  options,
  ...otherProps
}: any) => {
  const [results, setResults] = useState<Array<any>>([]);
  useEffect(() => {
    defaultValue && setResults([...defaultValue]);
  }, []);
  return (
    <div>
      {label ? (
        <div className=" text-neutral-900 mb-1 text-sm font-medium">
          {label}
        </div>
      ) : (
        <div className="h-5 mb-1"></div>
      )}

      <Select
        className={`min-h-[40px] w-full border  rounded   ${
          results.length === 0 && touched
            ? "border-red-600"
            : "border-neutral-300"
        } `}
        options={options}
        closeMenuOnSelect={false}
        defaultValue={defaultValue}
        isMulti={isMulti}
        placeholder={"Select an option"}
        onChange={(data: any) => {
          setResults([...data]);
          handleChange(data);
        }}
      />
      {results.length === 0 && touched && (
        <div className="text-red-600 text-xs mt-1">
          {"You should choose at least on currency"}
        </div>
      )}
    </div>
  );
};
