import { useState } from "react";

export const ProductSelectionItem = ({
  item,
  onHandleClick,
  isSelected,
}: {
  item: any;
  onHandleClick: any;
  isSelected?: boolean;
}) => {
  const [selected, setSelected] = useState(isSelected ? isSelected : false);
  return (
    <button
      onClick={() => {
        setSelected((prev) => !prev);
        onHandleClick(item);
      }}
      className={`${
        selected ? " border border-primary-500 bg-primary-100" : "border-none"
      } outline-none bg-neutral-100 py-1 px-2 rounded-full text-xs font-semibold text-neutral-600`}
    >
      {item.title}
    </button>
  );
};
