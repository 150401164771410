import { useFormik } from "formik";
import { merchantInformationSchema } from "../../../../validations/merchant-information";
import {
  FormInput,
  FormButton,
  FormSelect,
  FormMultiSelect,
} from "../../../common";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Mqr } from "../../../../core/types";
import {
  multiCountryList,
  categories,
  currencies_list,
} from "../../../../core/constants";

export const MerchantInformationForm = ({
  onHandleSubmit,
  added_payload,
}: any) => {
  const { state } = useLocation();
  const [currencies, setCurrencies] = useState<Array<any>>([]);
  const [countries, setCountries] = useState<Array<any>>([]);
  const [showApprove, setShowApprove] = useState(false);

  useEffect(() => {
    if (added_payload) {
      setCurrencies([...added_payload.currencies]);
    }
    if (added_payload) {
      let new_currencies: any = [];
      added_payload.currencies.map((currency: any, index: number) =>
        new_currencies.push({
          id: index + 1,
          value: currency,
          label: currency,
        })
      );
      setCurrencies([...new_currencies]);

      let new_countries: any = [];
      added_payload.countries.map((country: any, index: number) =>
        new_countries.push({
          id: index + 1,
          value: country,
          label: country,
        })
      );
      setCountries([...new_countries]);
    }
  }, []);
  const navigate = useNavigate();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    isValid,
    setValues,
  } = useFormik({
    initialValues: added_payload
      ? {
          avg_transaction_size: added_payload.avg_transaction_size,
          monthlyVolume: added_payload.monthlyVolume,
          min_transaction: added_payload.min_transaction,
          max_transaction: added_payload.max_transaction,
          currencies: added_payload.currencies.map(
            (currency: any, index: number) => ({
              id: index + 1,
              value: currency,
              label: currency,
            })
          ),
          countries: added_payload.countries.map(
            (country: any, index: number) => ({
              id: index + 1,
              value: country,
              label: country,
            })
          ),
        }
      : {
          avg_transaction_size: "",
          monthlyVolume: "",
          min_transaction: "",
          max_transaction: "",
          currencies: [] as Array<string>,
          countries: [] as Array<string>,
        },
    validationSchema: merchantInformationSchema,
    onSubmit: (values) => {
      setShowApprove(false);
      if (currencies.length > 0) {
        let newCurrencies: any[] = [];
        let newCountries: any[] = [];
        currencies.map((currency) => newCurrencies.push(currency.value));
        countries.map((country) => newCountries.push(country.value));
        setFieldValue("currencies", [...newCurrencies]);
        setFieldValue("countries", [...newCountries]);

        const payload = {
          ...state.company_profil,
          ...values,
          currencies: [...newCurrencies],
          countries: [...newCountries],
        };
        onHandleSubmit(payload);
      }
    },
  });
  const addMqr = (data: any) => {
    navigate("/account-setup/product-selection", {
      state: { mqr: data, added_products: state?.added_mqr?.products },
    });
  };
  return (
    <form
      className="flex flex-col gap-y-4 md:w-96 w-[320px]"
      onSubmit={handleSubmit}
    >
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Average Transaction Size"}
        placeholder={"$ 200"}
        id="avg_transaction_size"
        value={values.avg_transaction_size}
        errors={errors.avg_transaction_size}
        touched={touched.avg_transaction_size}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={(e: any) => {
          handleChange(e);
          setShowApprove(true);
        }}
        type={"number"}
        label={"Average monthly transactions volume"}
        placeholder={"$ 20.000"}
        id="monthlyVolume"
        value={values.monthlyVolume}
        errors={errors.monthlyVolume}
        touched={touched.monthlyVolume}
      />
      <div className="flex gap-x-2 justify-between">
        <div className="flex-1">
          <FormInput
            handleBlur={handleBlur}
            handleChange={(e: any) => {
              handleChange(e);
              setShowApprove(true);
            }}
            type={"number"}
            label={"Min Transaction Size"}
            placeholder={"$ 10"}
            id="min_transaction"
            value={values.min_transaction}
            errors={errors.min_transaction}
            touched={touched.min_transaction}
          />
        </div>
        <div className="flex-1">
          <FormInput
            handleBlur={handleBlur}
            handleChange={(e: any) => {
              handleChange(e);
              setShowApprove(true);
            }}
            type={"number"}
            label={"Max Transaction Size"}
            placeholder={"$ 1000"}
            id="max_transaction"
            value={values.max_transaction}
            errors={errors.max_transaction}
            touched={touched.max_transaction}
          />
        </div>
      </div>
      <FormMultiSelect
        placeholder={"Select an option"}
        options={currencies_list}
        // handleBlur={handleBlur}
        handleChange={(data: any) => {
          setCurrencies([...data]);
          setShowApprove(true);
        }}
        type={"text"}
        label={"Currencies"}
        id="currencies"
        // value={values.currencies}
        errors={errors.currencies}
        touched={touched.currencies}
        defaultValue={values.currencies}
        isMulti={true}
      />

      <FormMultiSelect
        placeholder={"Select an option"}
        options={multiCountryList}
        // handleBlur={handleBlur}
        handleChange={(data: any) => {
          setCountries([...data]);
          setShowApprove(true);
        }}
        type={"text"}
        label={"Countries of Operation"}
        id="countries"
        // value={values.countries}
        errors={errors.countries}
        touched={touched.countries}
        defaultValue={values.countries}
        isMulti={true}
      />
      {!showApprove ? (
        <></>
      ) : (
        <FormButton
          type={"submit"}
          title="Update"
          isLoding={false}
          disabled={false}
        />
      )}
    </form>
  );
};
