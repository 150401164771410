import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";

export function useSignUp() {
  return useMutation({
    mutationFn: (data: any) => client.post("register", data, { timeout: 5000 }),
    onError: (error: any) => {
      console.log(error);
    },
    onSuccess(response: any) {
      console.log("response", response);
    },
  });
}
