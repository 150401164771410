import { Link, useLocation } from "react-router-dom";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import { HiOutlineHome } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { RxHamburgerMenu } from "react-icons/rx";
import { useAuth } from "../../core/auth";
import { useAppStore } from "../../core/appStore";

const NavBar = () => {
  const { user } = useAppStore();
  const { signOut } = useAuth();
  const [toggleMenu, setToggleMenu] = useState(true);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const location = useLocation();
  const LinkComponent = ({
    title,
    handle,
    children,
    isParent,
  }: {
    title: string;
    handle: string;
    children?: any;
    isParent?: boolean;
  }) => {
    return (
      <li
        className={`h-[40px] flex ${
          (isParent && location.pathname.includes(handle)) ||
          location.pathname === handle
            ? " text-primary-500 bg-primary-100"
            : "text-neutral-500"
        }  items-center rounded-md `}
      >
        <Link
          className="w-full h-full flex gap-x-2 items-center px-2 "
          to={`${handle}`}
        >
          {children}
          {toggleMenu && <div>{title}</div>}
        </Link>
      </li>
    );
  };
  return (
    <div
      className={`${
        toggleMobileMenu ? " min-h-[100vh] absolute" : " min-h-0 relative"
      }  bg-white z-10  md:relative w-full md:w-auto flex md:min-h-[100vh] flex-col h-[90px] md:h-full p-6 border-r-[1px] border-neutral-100 justify-between `}
    >
      <div>
        <div
          className={`${
            toggleMenu ? " w-full md:min-w-[220px]" : "w-auto"
          } flex items-center justify-between`}
        >
          <Link to={"/"} className=" flex items-center gap-x-2">
            <img src="/logo/Logomark.png" alt="" className=" h-[34px]" />
            {toggleMenu && (
              <div className="font-bold text-neutral-900 text-base ">
                Freedom PSP
              </div>
            )}
          </Link>
          {toggleMenu && (
            <>
              <div className=" h-[36px] flex aspect-square bg-neutral-100 rounded ">
                <button
                  onClick={() => setToggleMenu((prev) => !prev)}
                  className=" hidden md:flex flex-1 items-center justify-center"
                >
                  <TbLayoutSidebarLeftCollapse
                    className=" text-neutral-500"
                    size={19}
                  />
                </button>
                <button
                  onClick={() => setToggleMobileMenu((prev) => !prev)}
                  className=" md:hidden flex flex-1 items-center justify-center"
                >
                  {toggleMobileMenu ? (
                    <IoMdClose className=" text-neutral-500" size={19} />
                  ) : (
                    <RxHamburgerMenu className=" text-neutral-500" size={19} />
                  )}
                </button>
              </div>
            </>
          )}
        </div>
        {!toggleMenu && (
          <button
            onClick={() => setToggleMenu((prev) => !prev)}
            className=" hidden md:flex mt-4 p-2 rounded bg-neutral-100"
          >
            <TbLayoutSidebarLeftExpand
              className=" text-neutral-500"
              size={24}
            />
          </button>
        )}
        <nav
          className={` ${toggleMobileMenu ? "block" : " hidden"} mt-8 md:block`}
        >
          <ul className=" flex flex-col gap-y-1">
            <LinkComponent isParent={true} title={"Account setup"} handle={"/"}>
              <HiOutlineHome size={24} className={` `} />
            </LinkComponent>
            {/* <LinkComponent
              title={"Account setup"}
              handle={"/account-setup/company-profile"}
              isParent={true}
              // sub_handle={[""]}
            >
              <HiOutlineHome size={24} className={` `} />
            </LinkComponent> */}
          </ul>
        </nav>
      </div>

      <div
        className={`${
          toggleMobileMenu ? "flex" : " hidden"
        } md:flex flex-col gap-y-20`}
      >
        {/* <nav className=" flex flex-col gap-y-1">
          <ul className=" flex flex-col gap-y-1">
            <LinkComponent title={"Settings"} handle={"/settings"}>
              <IoSettingsOutline size={24} className={` `} />
            </LinkComponent>
            <LinkComponent title={"Notifications"} handle={"/notifications"}>
              <IoMdNotificationsOutline size={24} className={` `} />
            </LinkComponent>
          </ul>
        </nav> */}
        <div className="flex gap-x-2">
          {toggleMenu && (
            <>
              <div className=" flex h-[40px] aspect-square rounded-full bg-primary-100"></div>
              <div className="h-full flex flex-col justify-between">
                <div className="font-bold text-sm text-neutral-900 capitalize">
                  {user?.name}
                </div>
                <div className=" text-neutral-500 text-sm">{user?.email}</div>
              </div>
            </>
          )}

          <div className="flex-1 flex justify-end items-center ">
            <button
              onClick={signOut}
              className=" bg-transparent outline-none border-none"
            >
              <MdLogout size={22} className=" text-neutral-600" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
