import { AuthHeader } from "../../modules/auth-header";
import { RegisterForm } from "../../modules/register-form";

export const Register = () => {
  return (
    <div className="py-2 px-3 w-full flex justify-center h-[100vh] overflow-y-auto y-global-bg">
      <div className=" w-full max-w-[1160px]">
        <div className="flex w-full min-h-[calc(100vh-16px)]">
          <div className="flex-1 flex justify-start flex-col items-center">
            <AuthHeader />
            <div className=" flex w-full gap-y-2 items-center flex-col mt-4">
              <div className=" text-3xl font-bold text-neutral-900">
                Sign up
              </div>
              <div className=" text-neutral-600 text-base">
                Fill the information below to open your account
              </div>
              <div className="mt-4 w-full sm:w-auto ">
                <RegisterForm />
              </div>
            </div>
          </div>
          <div className=" w-[50%] hidden lg:flex justify-center items-center ">
            <img
              src="/bgs/register-bg.png"
              className=" h-full max-h-[700px]"
              alt="login-bg.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
