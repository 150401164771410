import { useRef, useState } from "react";
import { useAddFile } from "../../../../services/api";
import { MdUploadFile } from "react-icons/md";
import { PopUpContainer } from "../../../common/pop-up-container";
export const FileUploader = ({
  doc,
  files_length,
  refetch,
}: {
  doc: any;
  files_length: number;
  refetch: any;
}) => {
  const { mutateAsync, isPending } = useAddFile();
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [docFile, setDocFile] = useState<any>(null);

  function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e?.target.files;
    if (!files) return;

    const file = files[0];

    // use the file

    setDocFile(file);
    console.log(file.size)
    const maxSizeInBytes = 8 * 1024 * 1024;
    if (file) {
      if (
        (file.type === "application/pdf" ||
          file.type.substring(0, 5) === "image") &&
        file.size < maxSizeInBytes
      ) {
        onSubmit(file);
      } else {
        setShowPopup(true);
      }
    }
  }

  const onSubmit = async (docFile: any) => {
    const formData = new FormData();
    formData.append(`files[${files_length}][file]`, docFile);
    formData.append(`files[${files_length}][category]`, "kyc");
    formData.append(`files[${files_length}][type]`, selectedFileName);
    formData.append(`files[${files_length}][name]`, docFile.name);
    await mutateAsync(formData, {
      onSuccess: (response) => {
        setDocFile(null);
        // handleFileUpload(null);
        refetch();
      },
      onError: (error) => {
        console.log("from ", error);
      },
    }).catch((error) => console.log());
  };
  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    console.log("entred", inputRef);
    if (!inputRef || !inputRef.current) return;

    inputRef.current.click();
  }

  const get_bk = (bytes: any) => {
    // return parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  };

  return (
    <>
      <div className="text-base text-neutral-900  font-semibold">
        Upload your {doc.label}
      </div>
      <button
        onClick={(e) => {
          setSelectedFileName(doc.type);
          handleButtonClick(e);
        }}
        className=" w-full rounded-xl border-dashed border border-neutral-300 items-center flex flex-col py-3 gap-y-2"
      >
        <div className="flex bg-neutral-100 justify-center items-center rounded-full w-11 h-11">
          <MdUploadFile size={26} className=" text-primary-500" />
        </div>
        <div className="text-sm">
          <span className=" text-primary-500 font-semibold">
            Click to Upload
          </span>
          {/* <span className=" text-neutral-500">or drag and drop</span> */}
        </div>
        <div className=" text-neutral-500 text-sm">(Max. File size: 8 MB)</div>
      </button>
      <input
        accept="image/png, image/jpeg, application/pdf"
        ref={inputRef}
        type="file"
        hidden
        onChange={handleFileUpload}
      />
      {showPopup && (
        <PopUpContainer
          submit_title="Got it"
          onSubmit={() => setShowPopup(false)}
        >
          <div className=" text-xl text-neutral-900 font-semibold">
            You can only upload images or pdf files
          </div>
          <div className=" text-red-500 text-xs">(Max. File size: 2 MB)</div>
        </PopUpContainer>
      )}
    </>
  );
};
