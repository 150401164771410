import { StepsContainer } from "../../../modules/mqr/components/steps-container";
import { CompanyProfileForm } from "../../../modules/mqr/templates/company-profile-form";
import { useLocation } from "react-router-dom";
export const CompanyProfile = () => {
  const { state } = useLocation();
  return (
    <div>
      <div className="font-bold text-2xl text-neutral-600 my-1 md:my-4 flex justify-center">
        MQR
      </div>
      <div className="my-3 md:my-6 text-center">
        <div className="font-bold text-2xl md:text-4xl text-neutral-900">
          Company Profile
        </div>
        <div className="text-sm md:text-base font-medium text-neutral-600 mt-1">
          We need some information about your company
        </div>
      </div>
      <div className="flex my-3 w-full gap-x-[3%] items-start flex-wrap justify-center lg:justify-normal gap-y-4 lg:flex-nowrap">
        <StepsContainer current_step={1} />
        <CompanyProfileForm added_mqr={state?.mqr} />
      </div>
    </div>
  );
};
