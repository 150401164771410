import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useMutation, useQuery } from "@tanstack/react-query";

export function useSetMgr() {
  return useMutation({
    mutationFn: (data: any) =>
      client.put("merchant/mqr", data, {
        timeout: 5000,
        headers: {
          Authorization: `Bearer ${getItem("auth")}`,
          "Access-Control-Allow-Origin": "*",
        },
      }),
    onError: (error: any) => {
      console.log(error);
    },
    onSuccess(response: any) {
      console.log("response", response);
    },
  });
}
