import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { forgotPasswordSchema } from "../../validations/forgot-password";
import { FormButton, FormInput } from "../common";
import { useForgotPassword } from "../../services/api/auth/use-forgot-password";
import { PopUpContainer } from "../common/pop-up-container";
import { useState } from "react";
export const ForgotPasswordForm = () => {
  const { mutateAsync, isPending } = useForgotPassword();
  const navigate = useNavigate();

  const sendEmail = async (data: any) => {
    await mutateAsync(data, {
      onSuccess: (response) => {
        navigate("/email-sent");
        // console.log("response from login form", response);
      },
      onError: (error) => {
        // console.log("from test test", error);
      },
    }).catch((error) => {
      if (error.status === 401) {
        // setErrorMessage("An account with this email already exixts");
      } else {
        // setErrorMessage("");
      }
      //   setShow(true);
    });
  };
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      sendEmail(values);
      //   navigate("/new-password");
    },
  });
  return (
    <form
      className="flex flex-col gap-y-4 min-w-[300px] md:min-w-[400px]"
      onSubmit={handleSubmit}
    >
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"email"}
        label={"Email"}
        placeholder={"name@example.com"}
        id="email"
        name="email"
        value={values.email}
        errors={errors.email}
        touched={touched.email}
      />
      <FormButton
        type={"submit"}
        title="Send"
        isLoding={isPending}
        disabled={isPending}
      />
    </form>
  );
};
