import { useEffect, useState } from "react";

export const PasswordMeter = ({ password }: { password: string }) => {
  const [lastState, setLastState] = useState(0);
  useEffect(() => {
    checkPassword();
  }, [password]);

  const checkPassword = () => {
    const specialChars = [
      "@",
      "$",
      ".",
      "#",
      "!",
      "%",
      "*",
      "?",
      "&",
      "^",
      "+",
      "-",
      "(",
      ")",
    ];
    let week_counter = 0;
    let fair_counter = 0;
    let good_counter = 0;
    let strong_counter = 0;
    if (password.length < 6) {
      week_counter = 0;
      fair_counter = 0;
      good_counter = 0;
      strong_counter = 0;
      setLastState(0);
      return;
    } else {
      week_counter = 1;
      if (
        specialChars.find((word) => password.includes(word)) &&
        password.length >= 10
      ) {
        strong_counter = 5;
      } else {
        strong_counter = 0;
      }
      if (password.search(/[0-9]/) >= 0) {
        good_counter = 3;
      } else {
        good_counter = 0;
      }
      if (password.search(/[A-Z]/) >= 0) {
        fair_counter = 2;
      } else {
        fair_counter = 0;
      }
    }
    setLastState(week_counter + fair_counter + good_counter + strong_counter);
  };
  return (
    <>
      {lastState > 0 ? (
        <div className={` h-1 w-full bg-neutral-100 mt-2 relative rounded `}>
          <div
            className={` ${
              lastState === 1
                ? " bg-red-500 w-[25%]"
                : lastState === 4 || lastState === 3
                ? " bg-yellow-500 w-[50%]"
                : lastState == 6 || lastState === 8
                ? " bg-green-300 w-[75%]"
                : "bg-green-500 w-[100%]"
            } absolute left-0 top-0 h-full rounded`}
          ></div>
          <div
            className={` ${
              lastState === 1
                ? " text-red-500"
                : lastState === 4 || lastState === 3
                ? " text-yellow-500 "
                : lastState == 6 || lastState === 8
                ? " text-green-300 "
                : "text-green-500 "
            } absolute right-0 text-xs bottom-[-20px]`}
          >
            {lastState === 1
              ? "Weak"
              : lastState === 4 || lastState === 3
              ? "Fair"
              : lastState == 6 || lastState === 8
              ? "Good"
              : "Strong"}
          </div>
        </div>
      ) : null}
    </>
  );
};
