import { FormInput, FormButton, FormSelect } from "../../../common";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { companyProfileSchema } from "../../../../validations/company-profile";
import { useState } from "react";
import { Mqr } from "../../../../core/types";
import {
  countryList,
  company_size,
  countries_code,
  categories,
} from "../../../../core/constants";

export const CompanyProfileForm = ({ added_mqr }: { added_mqr?: Mqr }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: added_mqr
      ? {
          citizenship: added_mqr.citizenship,
          position: added_mqr.position,
          companyName: added_mqr.companyName,
          registrationNumber: added_mqr.registrationNumber,
          registeredAddress: added_mqr.registeredAddress,
          country: added_mqr.country,
          phoneNumber: Number(added_mqr.phoneNumber),
          CompanySize: added_mqr.CompanySize,
          website: added_mqr.website,
          email: added_mqr.email,
          country_code: "+357",
          category: added_mqr.category,
          passportNumber: added_mqr.passportNumber,
        }
      : {
          passportNumber: "",
          citizenship: "",
          companyName: "",
          registrationNumber: "",
          registeredAddress: "",
          country: "",
          phoneNumber: "",
          CompanySize: "50_100",
          website: "",
          email: "",
          country_code: "+357",
          position: "",
          category: "",
        },
    validationSchema: companyProfileSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/account-setup/product-selection", {
          state: { mqr: values, added_mqr: added_mqr },
        });
      }, 0);
    },
  });

  return (
    <form
      className="flex flex-col gap-y-4 md:w-96 w-[320px]"
      onSubmit={handleSubmit}
    >
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Company name"}
        placeholder={"Enter your company name"}
        id="companyName"
        value={values.companyName}
        errors={errors.companyName}
        touched={touched.companyName}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Registration Number"}
        placeholder={"Enter your registration Number"}
        id="registrationNumber"
        value={values.registrationNumber}
        errors={errors.registrationNumber}
        touched={touched.registrationNumber}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Registred Address"}
        placeholder={"Enter your registred Address"}
        id="registeredAddress"
        value={values.registeredAddress}
        errors={errors.registeredAddress}
        touched={touched.registeredAddress}
      />
      <FormSelect
        placeholder={"Select an option"}
        options={countryList}
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Country "}
        id="country"
        value={values.country}
        errors={errors.country}
        touched={touched.country}
      />
      <FormSelect
        options={company_size}
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Company size"}
        placeholder={"Select a range of employees"}
        id="CompanySize"
        value={values.CompanySize}
        errors={errors.CompanySize}
        touched={touched.CompanySize}
      />
      <FormSelect
        placeholder={"Select an option"}
        options={countryList}
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Citizenship"}
        id="citizenship"
        value={values.citizenship}
        errors={errors.citizenship}
        touched={touched.citizenship}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Passeport Number"}
        placeholder={"LG23443"}
        id="passportNumber"
        value={values.passportNumber}
        errors={errors.passportNumber}
        touched={touched.passportNumber}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Position"}
        placeholder={"-"}
        id="position"
        value={values.position}
        errors={errors.position}
        touched={touched.position}
      />
      <FormSelect
        placeholder={"Select an option"}
        options={categories}
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Type"}
        id="category"
        value={values.category}
        errors={errors.category}
        touched={touched.category}
      />
      <div className="flex gap-x-3 justify-between ">
        <div className=" w-[90px]">
          <FormSelect
            options={countries_code}
            handleBlur={handleBlur}
            handleChange={handleChange}
            type={"text"}
            label={"Phone number"}
            placeholder={"Country code"}
            id="country_code"
            value={values.country_code}
            errors={errors.country_code}
            touched={touched.country_code}
          />
        </div>
        <div className="flex-1">
          <FormInput
            handleBlur={handleBlur}
            handleChange={handleChange}
            type={"number"}
            label={""}
            placeholder={"55123123"}
            id="phoneNumber"
            value={values.phoneNumber}
            errors={errors.phoneNumber}
            touched={touched.phoneNumber}
          />
        </div>
      </div>
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"text"}
        label={"Company website"}
        placeholder={"domain.com"}
        id="website"
        value={values.website}
        errors={errors.website}
        touched={touched.website}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"email"}
        label={"Email"}
        placeholder={"name@example.com"}
        id="email"
        value={values.email}
        errors={errors.email}
        touched={touched.email}
      />
      <FormButton
        type={"submit"}
        title="Next"
        isLoding={isLoading}
        disabled={false}
      />
    </form>
  );
};
