import { useFormik } from "formik";
import { FormButton, FormInput } from "../common";
import { newPasswordSchema } from "../../validations/new-password";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useResetPassword } from "../../services/api";
import { PopUpContainer } from "../common/pop-up-container";
import { useState } from "react";

export const NewPasswordForm = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const { mutateAsync, isPending } = useResetPassword();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values) => {
      let payload = {
        token: searchParams.get("token"),
        ...values,
      };
      resetPassword(payload);
    },
  });

  const resetPassword = async (data: any) => {
    await mutateAsync(data, {
      onSuccess: (response) => {
        // navigate("/new-password");
        setShow(true);
        console.log("response from login form", response);
      },
      onError: (error) => {
        // console.log("from test test", error);
      },
    }).catch((error) => {
      if (error.status === 401) {
        // setErrorMessage("An account with this email already exixts");
      } else {
        // setErrorMessage("");
      }
      //   setShowError(true);
    });
  };
  return (
    <form
      className="flex flex-col gap-y-4 min-w-[300px] md:min-w-[400px] px-3 md:px-0"
      onSubmit={handleSubmit}
    >
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"email"}
        label={"Email"}
        placeholder={"name@example.com"}
        id="email"
        name="email"
        value={values.email}
        errors={errors.email}
        touched={touched.email}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="password"
        placeholder="******"
        id="password"
        name="password"
        value={values.password}
        errors={errors.password}
        touched={touched.password}
        label={"Password"}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="password"
        placeholder="******"
        id="password_confirmation"
        name="password_confirmation"
        value={values.password_confirmation}
        errors={errors.password_confirmation}
        touched={touched.password_confirmation}
        label={"Confirm password"}
      />
      <FormButton
        type={"submit"}
        title="Submit"
        isLoding={isPending}
        disabled={isPending}
      />
      {show && (
        <PopUpContainer
          submit_title="login"
          //   onCancel={() => setShow(false)}
          onSubmit={() => navigate("/login")}
        >
          <>
            <div className="font-bold text-neutral-900 text-lg">
              Reset password
            </div>
            <div className=" text-sm text-neutral-600 my-1 ">
              {"Your password has been successfully reset"}
            </div>
          </>
        </PopUpContainer>
      )}
    </form>
  );
};
