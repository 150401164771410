export const multiCountryList = [
  {
    id: 1,
    label: "Afghanistan",
    "alpha-2": "AF",
    "alpha-3": "AFG",
    value: "AFG",
  },
  {
    id: 2,
    label: "Albania",
    "alpha-2": "AL",
    "alpha-3": "ALB",
    value: "ALB",
  },
  {
    id: 3,
    label: "Algeria",
    "alpha-2": "DZ",
    "alpha-3": "DZA",
    value: "DZA",
  },
  {
    id: 4,
    label: "Andorra",
    "alpha-2": "AD",
    "alpha-3": "AND",
    value: "AND",
  },
  {
    id: 5,
    label: "Angola",
    "alpha-2": "AO",
    "alpha-3": "AGO",
    value: "AGO",
  },
  {
    id: 6,
    label: "Antigua and Barbuda",
    "alpha-2": "AG",
    "alpha-3": "ATG",
    value: "ATG",
  },
  {
    id: 7,
    label: "Argentina",
    "alpha-2": "AR",
    "alpha-3": "ARG",
    value: "ARG",
  },
  {
    id: 8,
    label: "Armenia",
    "alpha-2": "AM",
    "alpha-3": "ARM",
    value: "ARM",
  },
  {
    id: 9,
    label: "Australia",
    "alpha-2": "AU",
    "alpha-3": "AUS",
    value: "AUS",
  },
  {
    id: 10,
    label: "Austria",
    "alpha-2": "AT",
    "alpha-3": "AUT",
    value: "AUT",
  },
  {
    id: 11,
    label: "Azerbaijan",
    "alpha-2": "AZ",
    "alpha-3": "AZE",
    value: "AZE",
  },
  {
    id: 12,
    label: "Bahamas",
    "alpha-2": "BS",
    "alpha-3": "BHS",
    value: "BHS",
  },
  {
    id: 13,
    label: "Bahrain",
    "alpha-2": "BH",
    "alpha-3": "BHR",
    value: "BHR",
  },
  {
    id: 14,
    label: "Bangladesh",
    "alpha-2": "BD",
    "alpha-3": "BGD",
    value: "BGD",
  },
  {
    id: 15,
    label: "Barbados",
    "alpha-2": "BB",
    "alpha-3": "BRB",
    value: "BRB",
  },
  {
    id: 16,
    label: "Belarus",
    "alpha-2": "BY",
    "alpha-3": "BLR",
    value: "BLR",
  },
  {
    id: 17,
    label: "Belgium",
    "alpha-2": "BE",
    "alpha-3": "BEL",
    value: "BEL",
  },
  {
    id: 18,
    label: "Belize",
    "alpha-2": "BZ",
    "alpha-3": "BLZ",
    value: "BLZ",
  },
  {
    id: 19,
    label: "Benin",
    "alpha-2": "BJ",
    "alpha-3": "BEN",
    value: "BEN",
  },
  {
    id: 20,
    label: "Bhutan",
    "alpha-2": "BT",
    "alpha-3": "BTN",
    value: "BTN",
  },
  {
    id: 21,
    label: "Bolivia (Plurinational State of)",
    "alpha-2": "BO",
    "alpha-3": "BOL",
    value: "BOL",
  },
  {
    id: 22,
    label: "Bosnia and Herzegovina",
    "alpha-2": "BA",
    "alpha-3": "BIH",
    value: "BIH",
  },
  {
    id: 23,
    label: "Botswana",
    "alpha-2": "BW",
    "alpha-3": "BWA",
    value: "BWA",
  },
  {
    id: 24,
    label: "Brazil",
    "alpha-2": "BR",
    "alpha-3": "BRA",
    value: "BRA",
  },
  {
    id: 25,
    label: "Brunei Darussalam",
    "alpha-2": "BN",
    "alpha-3": "BRN",
    value: "BRN",
  },
  {
    id: 26,
    label: "Bulgaria",
    "alpha-2": "BG",
    "alpha-3": "BGR",
    value: "BGR",
  },
  {
    id: 27,
    label: "Burkina Faso",
    "alpha-2": "BF",
    "alpha-3": "BFA",
    value: "BFA",
  },
  {
    id: 28,
    label: "Burundi",
    "alpha-2": "BI",
    "alpha-3": "BDI",
    value: "BDI",
  },
  {
    id: 29,
    label: "Côte d'Ivoire",
    "alpha-2": "CI",
    "alpha-3": "CIV",
    value: "CIV",
  },
  {
    id: 30,
    label: "Cabo Verde",
    "alpha-2": "CV",
    "alpha-3": "CPV",
    value: "CPV",
  },
  {
    id: 31,
    label: "Cambodia",
    "alpha-2": "KH",
    "alpha-3": "KHM",
    value: "KHM",
  },
  {
    id: 32,
    label: "Cameroon",
    "alpha-2": "CM",
    "alpha-3": "CMR",
    value: "CMR",
  },
  {
    id: 33,
    label: "Canada",
    "alpha-2": "CA",
    "alpha-3": "CAN",
    value: "CAN",
  },
  {
    id: 34,
    label: "Central African Republic",
    "alpha-2": "CF",
    "alpha-3": "CAF",
    value: "CAF",
  },
  {
    id: 35,
    label: "Chad",
    "alpha-2": "TD",
    "alpha-3": "TCD",
    value: "TCD",
  },
  {
    id: 36,
    label: "Chile",
    "alpha-2": "CL",
    "alpha-3": "CHL",
    value: "CHL",
  },
  {
    id: 37,
    label: "China",
    "alpha-2": "CN",
    "alpha-3": "CHN",
    value: "CHN",
  },
  {
    id: 38,
    label: "Colombia",
    "alpha-2": "CO",
    "alpha-3": "COL",
    value: "COL",
  },
  {
    id: 39,
    label: "Comoros",
    "alpha-2": "KM",
    "alpha-3": "COM",
    value: "COM",
  },
  {
    id: 40,
    label: "Congo (Republic of the)",
    "alpha-2": "CG",
    "alpha-3": "COG",
    value: "COG",
  },
  {
    id: 41,
    label: "Congo (Democratic Republic of the)",
    "alpha-2": "CD",
    "alpha-3": "COD",
    value: "COD",
  },
  {
    id: 42,
    label: "Costa Rica",
    "alpha-2": "CR",
    "alpha-3": "CRI",
    value: "CRI",
  },
  {
    id: 43,
    label: "Croatia",
    "alpha-2": "HR",
    "alpha-3": "HRV",
    value: "HRV",
  },
  {
    id: 44,
    label: "Cuba",
    "alpha-2": "CU",
    "alpha-3": "CUB",
    value: "CUB",
  },
  {
    id: 45,
    label: "Cyprus",
    "alpha-2": "CY",
    "alpha-3": "CYP",
    value: "CYP",
  },
  {
    id: 46,
    label: "Czechia",
    "alpha-2": "CZ",
    "alpha-3": "CZE",
    value: "CZE",
  },
  {
    id: 47,
    label: "Denmark",
    "alpha-2": "DK",
    "alpha-3": "DNK",
    value: "DNK",
  },
  {
    id: 48,
    label: "Djibouti",
    "alpha-2": "DJ",
    "alpha-3": "DJI",
    value: "DJI",
  },
  {
    id: 49,
    label: "Dominica",
    "alpha-2": "DM",
    "alpha-3": "DMA",
    value: "DMA",
  },
  {
    id: 50,
    label: "Dominican Republic",
    "alpha-2": "DO",
    "alpha-3": "DOM",
    value: "DOM",
  },
  {
    id: 51,
    label: "Ecuador",
    "alpha-2": "EC",
    "alpha-3": "ECU",
    value: "ECU",
  },
  {
    id: 52,
    label: "Egypt",
    "alpha-2": "EG",
    "alpha-3": "EGY",
    value: "EGY",
  },
  {
    id: 53,
    label: "El Salvador",
    "alpha-2": "SV",
    "alpha-3": "SLV",
    value: "SLV",
  },
  {
    id: 54,
    label: "Equatorial Guinea",
    "alpha-2": "GQ",
    "alpha-3": "GNQ",
    value: "GNQ",
  },
  {
    id: 55,
    label: "Eritrea",
    "alpha-2": "ER",
    "alpha-3": "ERI",
    value: "ERI",
  },
  {
    id: 56,
    label: "Estonia",
    "alpha-2": "EE",
    "alpha-3": "EST",
    value: "EST",
  },
  {
    id: 57,
    label: "Eswatini",
    "alpha-2": "SZ",
    "alpha-3": "SWZ",
    value: "SWZ",
  },
  {
    id: 58,
    label: "Ethiopia",
    "alpha-2": "ET",
    "alpha-3": "ETH",
    value: "ETH",
  },
  {
    id: 59,
    label: "Fiji",
    "alpha-2": "FJ",
    "alpha-3": "FJI",
    value: "FJI",
  },
  {
    id: 60,
    label: "Finland",
    "alpha-2": "FI",
    "alpha-3": "FIN",
    value: "FIN",
  },
  {
    id: 61,
    label: "France",
    "alpha-2": "FR",
    "alpha-3": "FRA",
    value: "FRA",
  },
  {
    id: 62,
    label: "Gabon",
    "alpha-2": "GA",
    "alpha-3": "GAB",
    value: "GAB",
  },
  {
    id: 63,
    label: "Gambia",
    "alpha-2": "GM",
    "alpha-3": "GMB",
    value: "GMB",
  },
  {
    id: 64,
    label: "Georgia",
    "alpha-2": "GE",
    "alpha-3": "GEO",
    value: "GEO",
  },
  {
    id: 65,
    label: "Germany",
    "alpha-2": "DE",
    "alpha-3": "DEU",
    value: "DEU",
  },
  {
    id: 66,
    label: "Ghana",
    "alpha-2": "GH",
    "alpha-3": "GHA",
    value: "GHA",
  },
  {
    id: 67,
    label: "Greece",
    "alpha-2": "GR",
    "alpha-3": "GRC",
    value: "GRC",
  },
  {
    id: 68,
    label: "Grenada",
    "alpha-2": "GD",
    "alpha-3": "GRD",
    value: "GRD",
  },
  {
    id: 69,
    label: "Guatemala",
    "alpha-2": "GT",
    "alpha-3": "GTM",
    value: "GTM",
  },
  {
    id: 70,
    label: "Guinea",
    "alpha-2": "GN",
    "alpha-3": "GIN",
    value: "GIN",
  },
  {
    id: 71,
    label: "Guinea-Bissau",
    "alpha-2": "GW",
    "alpha-3": "GNB",
    value: "GNB",
  },
  {
    id: 72,
    label: "Guyana",
    "alpha-2": "GY",
    "alpha-3": "GUY",
    value: "GUY",
  },
  {
    id: 73,
    label: "Haiti",
    "alpha-2": "HT",
    "alpha-3": "HTI",
    value: "HTI",
  },
  {
    id: 74,
    label: "Honduras",
    "alpha-2": "HN",
    "alpha-3": "HND",
    value: "HND",
  },
  {
    id: 75,
    label: "Hungary",
    "alpha-2": "HU",
    "alpha-3": "HUN",
    value: "HUN",
  },
  {
    id: 76,
    label: "Iceland",
    "alpha-2": "IS",
    "alpha-3": "ISL",
    value: "ISL",
  },
  {
    id: 77,
    label: "India",
    "alpha-2": "IN",
    "alpha-3": "IND",
    value: "IND",
  },
  {
    id: 78,
    label: "Indonesia",
    "alpha-2": "ID",
    "alpha-3": "IDN",
    value: "IDN",
  },
  {
    id: 79,
    label: "Iran (Islamic Republic of)",
    "alpha-2": "IR",
    "alpha-3": "IRN",
    value: "IRN",
  },
  {
    id: 80,
    label: "Iraq",
    "alpha-2": "IQ",
    "alpha-3": "IRQ",
    value: "IRQ",
  },
  {
    id: 81,
    label: "Ireland",
    "alpha-2": "IE",
    "alpha-3": "IRL",
    value: "IRL",
  },
  {
    id: 82,
    label: "Israel",
    "alpha-2": "IL",
    "alpha-3": "ISR",
    value: "ISR",
  },
  {
    id: 83,
    label: "Italy",
    "alpha-2": "IT",
    "alpha-3": "ITA",
    value: "ITA",
  },
  {
    id: 84,
    label: "Jamaica",
    "alpha-2": "JM",
    "alpha-3": "JAM",
    value: "JAM",
  },
  {
    id: 85,
    label: "Japan",
    "alpha-2": "JP",
    "alpha-3": "JPN",
    value: "JPN",
  },
  {
    id: 86,
    label: "Jordan",
    "alpha-2": "JO",
    "alpha-3": "JOR",
    value: "JOR",
  },
  {
    id: 87,
    label: "Kazakhstan",
    "alpha-2": "KZ",
    "alpha-3": "KAZ",
    value: "KAZ",
  },
  {
    id: 88,
    label: "Kenya",
    "alpha-2": "KE",
    "alpha-3": "KEN",
    value: "KEN",
  },
  {
    id: 89,
    label: "Kiribati",
    "alpha-2": "KI",
    "alpha-3": "KIR",
    value: "KIR",
  },
  {
    id: 90,
    label: "Korea (Democratic People's Republic of)",
    "alpha-2": "KP",
    "alpha-3": "PRK",
    value: "PRK",
  },
  {
    id: 91,
    label: "Korea (Republic of)",
    "alpha-2": "KR",
    "alpha-3": "KOR",
    value: "KOR",
  },
  {
    id: 92,
    label: "Kuwait",
    "alpha-2": "KW",
    "alpha-3": "KWT",
    value: "KWT",
  },
  {
    id: 93,
    label: "Kyrgyzstan",
    "alpha-2": "KG",
    "alpha-3": "KGZ",
    value: "KGZ",
  },
  {
    id: 94,
    label: "Laos",
    "alpha-2": "LA",
    "alpha-3": "LAO",
    value: "LAO",
  },
  {
    id: 95,
    label: "Latvia",
    "alpha-2": "LV",
    "alpha-3": "LVA",
    value: "LVA",
  },
  {
    id: 96,
    label: "Lebanon",
    "alpha-2": "LB",
    "alpha-3": "LBN",
    value: "LBN",
  },
  {
    id: 97,
    label: "Lesotho",
    "alpha-2": "LS",
    "alpha-3": "LSO",
    value: "LSO",
  },
  {
    id: 98,
    label: "Liberia",
    "alpha-2": "LR",
    "alpha-3": "LBR",
    value: "LBR",
  },
  {
    id: 99,
    label: "Libya",
    "alpha-2": "LY",
    "alpha-3": "LBY",
    value: "LBY",
  },
  {
    id: 100,
    label: "Liechtenstein",
    "alpha-2": "LI",
    "alpha-3": "LIE",
    value: "LIE",
  },
  {
    id: 101,
    label: "Lithuania",
    "alpha-2": "LT",
    "alpha-3": "LTU",
    value: "LTU",
  },
  {
    id: 102,
    label: "Luxembourg",
    "alpha-2": "LU",
    "alpha-3": "LUX",
    value: "LUX",
  },
  {
    id: 103,
    label: "Madagascar",
    "alpha-2": "MG",
    "alpha-3": "MDG",
    value: "MDG",
  },
  {
    id: 104,
    label: "Malawi",
    "alpha-2": "MW",
    "alpha-3": "MWI",
    value: "MWI",
  },
  {
    id: 105,
    label: "Malaysia",
    "alpha-2": "MY",
    "alpha-3": "MYS",
    value: "MYS",
  },
  {
    id: 106,
    label: "Maldives",
    "alpha-2": "MV",
    "alpha-3": "MDV",
    value: "MDV",
  },
  {
    id: 107,
    label: "Mali",
    "alpha-2": "ML",
    "alpha-3": "MLI",
    value: "MLI",
  },
  {
    id: 108,
    label: "Malta",
    "alpha-2": "MT",
    "alpha-3": "MLT",
    value: "MLT",
  },
  {
    id: 109,
    label: "Marshall Islands",
    "alpha-2": "MH",
    "alpha-3": "MHL",
    value: "MHL",
  },
  {
    id: 110,
    label: "Mauritania",
    "alpha-2": "MR",
    "alpha-3": "MRT",
    value: "MRT",
  },
  {
    id: 111,
    label: "Mauritius",
    "alpha-2": "MU",
    "alpha-3": "MUS",
    value: "MUS",
  },
  {
    id: 112,
    label: "Mexico",
    "alpha-2": "MX",
    "alpha-3": "MEX",
    value: "MEX",
  },
  {
    id: 113,
    label: "Micronesia (Federated States of)",
    "alpha-2": "FM",
    "alpha-3": "FSM",
    value: "FSM",
  },
  {
    id: 114,
    label: "Moldova (Republic of)",
    "alpha-2": "MD",
    "alpha-3": "MDA",
    value: "MDA",
  },
  {
    id: 115,
    label: "Monaco",
    "alpha-2": "MC",
    "alpha-3": "MCO",
    value: "MCO",
  },
  {
    id: 116,
    label: "Mongolia",
    "alpha-2": "MN",
    "alpha-3": "MNG",
    value: "MNG",
  },
  {
    id: 117,
    label: "Montenegro",
    "alpha-2": "ME",
    "alpha-3": "MNE",
    value: "MNE",
  },
  {
    id: 118,
    label: "Morocco",
    "alpha-2": "MA",
    "alpha-3": "MAR",
    value: "MAR",
  },
  {
    id: 119,
    label: "Mozambique",
    "alpha-2": "MZ",
    "alpha-3": "MOZ",
    value: "MOZ",
  },
  {
    id: 120,
    label: "Myanmar",
    "alpha-2": "MM",
    "alpha-3": "MMR",
    value: "MMR",
  },
  {
    id: 121,
    label: "Namibia",
    "alpha-2": "NA",
    "alpha-3": "NAM",
    value: "NAM",
  },
  {
    id: 122,
    label: "Nauru",
    "alpha-2": "NR",
    "alpha-3": "NRU",
    value: "NRU",
  },
  {
    id: 123,
    label: "Nepal",
    "alpha-2": "NP",
    "alpha-3": "NPL",
    value: "NPL",
  },
  {
    id: 124,
    label: "Netherlands",
    "alpha-2": "NL",
    "alpha-3": "NLD",
    value: "NLD",
  },
  {
    id: 125,
    label: "New Zealand",
    "alpha-2": "NZ",
    "alpha-3": "NZL",
    value: "NZL",
  },
  {
    id: 126,
    label: "Nicaragua",
    "alpha-2": "NI",
    "alpha-3": "NIC",
    value: "NIC",
  },
  {
    id: 127,
    label: "Niger",
    "alpha-2": "NE",
    "alpha-3": "NER",
    value: "NER",
  },
  {
    id: 128,
    label: "Nigeria",
    "alpha-2": "NG",
    "alpha-3": "NGA",
    value: "NGA",
  },
  {
    id: 129,
    label: "North Macedonia",
    "alpha-2": "MK",
    "alpha-3": "MKD",
    value: "MKD",
  },
  {
    id: 130,
    label: "Norway",
    "alpha-2": "NO",
    "alpha-3": "NOR",
    value: "NOR",
  },
  {
    id: 131,
    label: "Oman",
    "alpha-2": "OM",
    "alpha-3": "OMN",
    value: "OMN",
  },
  {
    id: 132,
    label: "Pakistan",
    "alpha-2": "PK",
    "alpha-3": "PAK",
    value: "PAK",
  },
  {
    id: 133,
    label: "Palau",
    "alpha-2": "PW",
    "alpha-3": "PLW",
    value: "PLW",
  },
  {
    id: 134,
    label: "Palestine, State of",
    "alpha-2": "PS",
    "alpha-3": "PSE",
    value: "PSE",
  },
  {
    id: 135,
    label: "Panama",
    "alpha-2": "PA",
    "alpha-3": "PAN",
    value: "PAN",
  },
  {
    id: 136,
    label: "Papua New Guinea",
    "alpha-2": "PG",
    "alpha-3": "PNG",
    value: "PNG",
  },
  {
    id: 137,
    label: "Paraguay",
    "alpha-2": "PY",
    "alpha-3": "PRY",
    value: "PRY",
  },
  {
    id: 138,
    label: "Peru",
    "alpha-2": "PE",
    "alpha-3": "PER",
    value: "PER",
  },
  {
    id: 139,
    label: "Philippines",
    "alpha-2": "PH",
    "alpha-3": "PHL",
    value: "PHL",
  },
  {
    id: 140,
    label: "Poland",
    "alpha-2": "PL",
    "alpha-3": "POL",
    value: "POL",
  },
  {
    id: 141,
    label: "Portugal",
    "alpha-2": "PT",
    "alpha-3": "PRT",
    value: "PRT",
  },
  {
    id: 142,
    label: "Qatar",
    "alpha-2": "QA",
    "alpha-3": "QAT",
    value: "QAT",
  },
  {
    id: 143,
    label: "Romania",
    "alpha-2": "RO",
    "alpha-3": "ROU",
    value: "ROU",
  },
  {
    id: 144,
    label: "Russia",
    "alpha-2": "RU",
    "alpha-3": "RUS",
    value: "RUS",
  },
  {
    id: 145,
    label: "Rwanda",
    "alpha-2": "RW",
    "alpha-3": "RWA",
    value: "RWA",
  },
  {
    id: 146,
    label: "Saint Kitts and Nevis",
    "alpha-2": "KN",
    "alpha-3": "KNA",
    value: "KNA",
  },
  {
    id: 147,
    label: "Saint Lucia",
    "alpha-2": "LC",
    "alpha-3": "LCA",
    value: "LCA",
  },
  {
    id: 148,
    label: "Saint Vincent and the Grenadines",
    "alpha-2": "VC",
    "alpha-3": "VCT",
    value: "VCT",
  },
  {
    id: 149,
    label: "Samoa",
    "alpha-2": "WS",
    "alpha-3": "WSM",
    value: "WSM",
  },
  {
    id: 150,
    label: "San Marino",
    "alpha-2": "SM",
    "alpha-3": "SMR",
    value: "SMR",
  },
  {
    id: 151,
    label: "Sao Tome and Principe",
    "alpha-2": "ST",
    "alpha-3": "STP",
    value: "STP",
  },
  {
    id: 152,
    label: "Saudi Arabia",
    "alpha-2": "SA",
    "alpha-3": "SAU",
    value: "SAU",
  },
  {
    id: 153,
    label: "Senegal",
    "alpha-2": "SN",
    "alpha-3": "SEN",
    value: "SEN",
  },
  {
    id: 154,
    label: "Serbia",
    "alpha-2": "RS",
    "alpha-3": "SRB",
    value: "SRB",
  },
  {
    id: 155,
    label: "Seychelles",
    "alpha-2": "SC",
    "alpha-3": "SYC",
    value: "SYC",
  },
  {
    id: 156,
    label: "Sierra Leone",
    "alpha-2": "SL",
    "alpha-3": "SLE",
    value: "SLE",
  },
  {
    id: 157,
    label: "Singapore",
    "alpha-2": "SG",
    "alpha-3": "SGP",
    value: "SGP",
  },
  {
    id: 158,
    label: "Slovakia",
    "alpha-2": "SK",
    "alpha-3": "SVK",
    value: "SVK",
  },
  {
    id: 159,
    label: "Slovenia",
    "alpha-2": "SI",
    "alpha-3": "SVN",
    value: "SVN",
  },
  {
    id: 160,
    label: "Solomon Islands",
    "alpha-2": "SB",
    "alpha-3": "SLB",
    value: "SLB",
  },
  {
    id: 161,
    label: "Somalia",
    "alpha-2": "SO",
    "alpha-3": "SOM",
    value: "SOM",
  },
  {
    id: 162,
    label: "South Africa",
    "alpha-2": "ZA",
    "alpha-3": "ZAF",
    value: "ZAF",
  },
  {
    id: 163,
    label: "South Sudan",
    "alpha-2": "SS",
    "alpha-3": "SSD",
    value: "SSD",
  },
  {
    id: 164,
    label: "Spain",
    "alpha-2": "ES",
    "alpha-3": "ESP",
    value: "ESP",
  },
  {
    id: 165,
    label: "Sri Lanka",
    "alpha-2": "LK",
    "alpha-3": "LKA",
    value: "LKA",
  },
  {
    id: 166,
    label: "Sudan",
    "alpha-2": "SD",
    "alpha-3": "SDN",
    value: "SDN",
  },
  {
    id: 167,
    label: "Suriname",
    "alpha-2": "SR",
    "alpha-3": "SUR",
    value: "SUR",
  },
  {
    id: 168,
    label: "Sweden",
    "alpha-2": "SE",
    "alpha-3": "SWE",
    value: "SWE",
  },
  {
    id: 169,
    label: "Switzerland",
    "alpha-2": "CH",
    "alpha-3": "CHE",
    value: "CHE",
  },
  {
    id: 170,
    label: "Syria",
    "alpha-2": "SY",
    "alpha-3": "SYR",
    value: "SYR",
  },
  {
    id: 171,
    label: "Taiwan",
    "alpha-2": "TW",
    "alpha-3": "TWN",
    value: "TWN",
  },
  {
    id: 172,
    label: "Tajikistan",
    "alpha-2": "TJ",
    "alpha-3": "TJK",
    value: "TJK",
  },
  {
    id: 173,
    label: "Tanzania, United Republic of",
    "alpha-2": "TZ",
    "alpha-3": "TZA",
    value: "TZA",
  },
  {
    id: 174,
    label: "Thailand",
    "alpha-2": "TH",
    "alpha-3": "THA",
    value: "THA",
  },
  {
    id: 175,
    label: "Timor-Leste",
    "alpha-2": "TL",
    "alpha-3": "TLS",
    value: "TLS",
  },
  {
    id: 176,
    label: "Togo",
    "alpha-2": "TG",
    "alpha-3": "TGO",
    value: "TGO",
  },
  {
    id: 177,
    label: "Tonga",
    "alpha-2": "TO",
    "alpha-3": "TON",
    value: "TON",
  },
  {
    id: 178,
    label: "Trinidad and Tobago",
    "alpha-2": "TT",
    "alpha-3": "TTO",
    value: "TTO",
  },
  {
    id: 179,
    label: "Tunisia",
    "alpha-2": "TN",
    "alpha-3": "TUN",
    value: "TUN",
  },
  {
    id: 180,
    label: "Turkey",
    "alpha-2": "TR",
    "alpha-3": "TUR",
    value: "TUR",
  },
  {
    id: 181,
    label: "Turkmenistan",
    "alpha-2": "TM",
    "alpha-3": "TKM",
    value: "TKM",
  },
  {
    id: 182,
    label: "Tuvalu",
    "alpha-2": "TV",
    "alpha-3": "TUV",
    value: "TUV",
  },
  {
    id: 183,
    label: "Uganda",
    "alpha-2": "UG",
    "alpha-3": "UGA",
    value: "UGA",
  },
  {
    id: 184,
    label: "Ukraine",
    "alpha-2": "UA",
    "alpha-3": "UKR",
    value: "UKR",
  },
  {
    id: 185,
    label: "United Arab Emirates",
    "alpha-2": "AE",
    "alpha-3": "ARE",
    value: "ARE",
  },
  {
    id: 186,
    label: "United Kingdom of Great Britain and Northern Ireland",
    "alpha-2": "GB",
    "alpha-3": "GBR",
    value: "GBR",
  },
  {
    id: 187,
    label: "United States of America",
    "alpha-2": "US",
    "alpha-3": "USA",
    value: "USA",
  },
  {
    id: 188,
    label: "Uruguay",
    "alpha-2": "UY",
    "alpha-3": "URY",
    value: "URY",
  },
  {
    id: 189,
    label: "Uzbekistan",
    "alpha-2": "UZ",
    "alpha-3": "UZB",
    value: "UZB",
  },
  {
    id: 190,
    label: "Vanuatu",
    "alpha-2": "VU",
    "alpha-3": "VUT",
    value: "VUT",
  },
  {
    id: 191,
    label: "Venezuela (Bolivarian Republic of)",
    "alpha-2": "VE",
    "alpha-3": "VEN",
    value: "VEN",
  },
  {
    id: 192,
    label: "Vietnam",
    "alpha-2": "VN",
    "alpha-3": "VNM",
    value: "VNM",
  },
  {
    id: 193,
    label: "Yemen",
    "alpha-2": "YE",
    "alpha-3": "YEM",
    value: "YEM",
  },
  {
    id: 194,
    label: "Zambia",
    "alpha-2": "ZM",
    "alpha-3": "ZMB",
    value: "ZMB",
  },
  {
    id: 195,
    label: "Zimbabwe",
    "alpha-2": "ZW",
    "alpha-3": "ZWE",
    value: "ZWE",
  },
];
