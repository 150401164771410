import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";

export function useAddFile() {
  return useMutation({
    mutationFn: (data: any) =>
      client.post("merchant/files/upload", data, {
        timeout: 5000,
        headers: {
          Authorization: `Bearer ${getItem("auth")}`,
        },
      }),
    onError: (error: any) => {
      console.log(error);
    },
    onSuccess(response: any) {
      console.log("response", response);
    },
  });
}
