import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Step = ({
  currentStep,
  step,
}: {
  currentStep: number;
  step: {
    id: number;
    label: string;
    rank: number;
    isDone: boolean;
    handle: string;
    payload?: any;
    offer_status?: string;
    filesApproved?: number;
  };
}) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() =>
        navigate(
          `${step.handle}`,
          step.payload && { state: { mqr: step.payload } }
        )
      }
      className="flex items-center gap-x-2"
    >
      <div
        className={` ${
          (step.offer_status && step.offer_status === "document_created") ||
          (step.filesApproved && step.filesApproved < 4 && !step.isDone)
            ? "bg-primary-500"
            : step.isDone
            ? "bg-green-600"
            : "bg-neutral-300"
        } rounded-full h-6 aspect-square flex items-center justify-center`}
      >
        <div className="text-sm text-white">
          {step.offer_status && step.offer_status === "document_created" ? (
            <div className="text-sm text-white">{step.rank}</div>
          ) : step.isDone ? (
            <FaCheck size={16} color="white" />
          ) : (
            <div className="text-sm text-white">{step.rank}</div>
          )}
        </div>
      </div>
      <div
        className={` ${
          step.offer_status && step.offer_status === "document_created"
            ? ""
            : "hover:underline hover:text-primary-500"
        } text-sm text-neutral-900`}
      >
        {step.filesApproved && step.filesApproved < 4 ? (
          <>
            {step.label} ({step.filesApproved} approved)
          </>
        ) : step.offer_status && step.offer_status === "document_created" ? (
          <Link target="blank" to={step.payload}>
            Offer has been sent, please click here to sign
          </Link>
        ) : (step.offer_status && step.offer_status === "document_signed") ||
          (step.offer_status &&
            step.offer_status === "email_validation_waived") ? (
          step.payload != "" ? (
            <Link target="blank" to={step.payload}>
              View offer
            </Link>
          ) : (
            "View offer"
          )
        ) : (
          step.label
        )}
      </div>
    </button>
  );
};
