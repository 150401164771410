import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const StepsProgress = ({
  mqr,
  kyb,
  offer_signed,
}: {
  mqr: boolean;
  kyb: boolean;
  offer_signed: boolean;
}) => {
  // let percentage = 0;
  // percentage = kyb ? 75 : offer_signed ? 50 : mqr ? 25 : 0;
  let is_kyb = kyb ? 25 : 0;
  let is_offer_signed = offer_signed ? 25 : 0;
  let is_mqr = mqr ? 25 : 0;

  return (
    <div className="flex gap-x-2 items-center">
      <div className=" w-[80px] h-[80px]">
        <CircularProgressbar
          className=""
          value={is_kyb + is_offer_signed + is_mqr}
          text={`${is_kyb + is_offer_signed + is_mqr}%`}
        />
      </div>

      <div>
        <div className=" font-semibold">
          {is_kyb + is_offer_signed + is_mqr}%
        </div>
        <div className=" text-base text-neutral-600">
          of the account setup is completed
        </div>
      </div>
    </div>
  );
};
