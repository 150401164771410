import { ReactNode } from "react";

export const PopUpContainer = ({
  children,
  cancel_title,
  submit_title,
  onCancel,
  onSubmit,
  with_cancel,
}: {
  children: ReactNode;
  cancel_title?: string;
  submit_title?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  with_cancel?: boolean;
}) => {
  return (
    <div className=" z-10 fixed h-full w-full top-0 left-0 flex items-center justify-center bg-black/20">
      <div className=" w-full max-w-[400px] rounded-lg p-4 bg-white shadow-xl shadow-black/30">
        {children}
        <div className=" mt-3 flex w-full justify-end gap-x-3">
          {with_cancel && (
            <button
              onClick={onCancel}
              className=" capitalize items-center rounded bg-white text-sm text-neutral-600 border border-neutral-300 py-1 px-5"
            >
              {cancel_title ? cancel_title : "Cancel"}
            </button>
          )}

          <button
            onClick={onSubmit}
            className=" capitalize items-center rounded bg-primary-500 text-sm text-white  py-1 px-5 "
          >
            {submit_title ? submit_title : "Okay"}
          </button>
        </div>
      </div>
    </div>
  );
};
