import { Spinner } from "../spinner";

export const FormButton = ({
  disabled,
  isLoding,
  title,
  type,
  onClick,
  ...otherProps
}: {
  disabled: boolean;
  isLoding: boolean;
  title: string;
  onClick?: any;
  type: "button" | "submit" | "reset";
}) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        {...otherProps}
        className={` ${
          disabled ? " bg-neutral-300" : " bg-blue-400"
        } rounded h-[40px] w-full text-white`}
      >
        {isLoding ? <Spinner /> : title}
      </button>
    </>
  );
};
