import { FaCheck } from "react-icons/fa6";
export const Step = ({
  title,
  step_number,
  current_step,
}: {
  title: string;
  step_number: number;
  current_step: number;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <div
        className={`${
          current_step > step_number
            ? "bg-green-600"
            : current_step === step_number
            ? "bg-primary-500"
            : "bg-neutral-300"
        } rounded-full h-6 aspect-square  flex items-center justify-center`}
      >
        {current_step > step_number ? (
          <FaCheck size={16} color="white" />
        ) : current_step === step_number ? (
          <div className="text-sm text-white">{step_number}</div>
        ) : (
          <div className="text-sm text-white">{step_number}</div>
        )}
      </div>
      <div
        className={` ${
          current_step > step_number
            ? "text-green-600"
            : current_step === step_number
            ? "text-neutral-900 font-medium"
            : "text-neutral-600"
        } text-sm`}
      >
        {title}
      </div>
    </div>
  );
};
