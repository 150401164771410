import { FormInput, FormButton, FormCheckBox } from "../common";
import { useFormik } from "formik";
import { loginSchema } from "../../validations/login-validation";
import { Recaptcha } from "../common/recaptcha";
import { useSignIn } from "../../services/api";
import { useState } from "react";
import { PopUpContainer } from "../common/pop-up-container";
import { Link } from "react-router-dom";

export const LoginForm = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { mutateAsync, isPending, isError, error } = useSignIn();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      login(values);
    },
  });
  const login = async (data: any) => {
    setShowError(false);
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    await mutateAsync(formData, {
      onSuccess: (response) => {
        // console.log("response from login form", response);
      },
      onError: (error) => {
        console.log("from login", error);
      },
    }).catch((error) => {
      // if (error.status === 401) {
      setErrorMessage(
        "The email or password you entered is incorrected. Please double-check and try again."
      );
      // } else {
      //   setErrorMessage("");
      // }
      setShowError(true);
    });
  };
  return (
    <form
      className="flex flex-col gap-y-4 min-w-[300px] md:min-w-[400px]"
      onSubmit={handleSubmit}
    >
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        type={"email"}
        label={"Email"}
        placeholder={"name@example.com"}
        id="email"
        value={values.email}
        errors={errors.email}
        touched={touched.email}
      />
      <FormInput
        label={"Password"}
        handleBlur={handleBlur}
        handleChange={handleChange}
        type="password"
        placeholder="******"
        id="password"
        name="password"
        value={values.password}
        errors={errors.password}
        touched={touched.password}
        // login={true}
      />
      <Link to={"/forgot-password"}>
        <div className=" text-sm font-semibold text-primary-500">
          Forgot your password?
        </div>
      </Link>
      <FormCheckBox
        value={rememberMe}
        title="Remember me"
        onClick={() => setRememberMe((prev) => !prev)}
      />
      {/* <Recaptcha /> */}
      <FormButton
        type={"submit"}
        title="Login"
        isLoding={isPending}
        disabled={isPending}
      />

      {showError && (
        <span className=" text-sm text-red-500 max-w-[400px]  ">
          {errorMessage}
        </span>
      )}

      {/* {showError && (
        <PopUpContainer
          onCancel={() => setShowError(false)}
          onSubmit={() => setShowError(false)}
          submit_title="Got it"
        >
          <>
            <div className="font-bold text-neutral-900 text-lg">
              {errorMessage}
            </div>
          </>
        </PopUpContainer>
      )} */}
    </form>
  );
};
