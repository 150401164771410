import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";
import { useQuery } from "@tanstack/react-query";
import { Mqr } from "../../../core/types";

const getMqr = async () => {
  const { data } = await client.get(`merchant/mqr`, {
    timeout: 2000, // since it can be heavy too
    headers: {
      Authorization: `Bearer ${getItem("auth")}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
  return data.data as Mqr;
};

export function useMqr() {
  return useQuery({ queryKey: ["get-mqr"], queryFn: getMqr });
}
