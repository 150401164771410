import { useEffect, useState } from "react";
import { FormButton } from "../../../modules/common";
import { ProductSelectionsContainer } from "../../../modules/mqr/components/product-selections-container";
import { StepsContainer } from "../../../modules/mqr/components/steps-container";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateMqr, useSetMgr } from "../../../services/api";
import { useAppStore } from "../../../core/appStore";
import { PopUpContainer } from "../../../modules/common/pop-up-container";
import { MerchantInformationForm } from "../../../modules/mqr/templates/merchant-information-form";

export const ProductSelection = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const { user } = useAppStore();
  const { mutateAsync, isPending, isError, error } = useCreateMqr();
  const {
    mutateAsync: updateAsyncMqr,
    isPending: isPendingUpdate,
    isError: isErrorUpdate,
  } = useSetMgr();
  const [products, setProducts] = useState<Array<string>>([]);
  const [isWire, setIsWire] = useState<Boolean>(false);
  const [isCc, setIsCc] = useState<Boolean>(false);
  const [wire, setWire] = useState<any>(null);
  const [cc, setCc] = useState<any>(null);
  const [isDisabled, setIsDisebled] = useState<boolean>(true);
  const { state } = useLocation();

  const navigate = useNavigate();
  const product_selections = [
    {
      id: 1,
      title: "Wire Transfer Services",
    },
    {
      id: 2,
      title: "Card Processing Services: 3DS, 2D",
    },
    {
      id: 3,
      title: "Multi-Party Payments and Payouts",
    },
    {
      id: 4,
      title: "Offramp (crypto payout)",
    },
    {
      id: 5,
      title: "Virtual card crypto, cards issuing",
    },
  ];
  const onSubmit = () => {
    const mqr = state.mrq;
    addMqr();
  };

  useEffect(() => {
    if (state?.added_mqr) {
      setProducts([...state?.added_mqr?.products]);
      setWire(state?.added_mqr?.wire);
      setCc(state?.added_mqr?.cc);
      setIsDisebled(false);
    }
  }, []);

  const manageProducts = (item: any) => {
    let newProducts = products;
    let exists = false;
    products.map((product: any, index) => {
      if (product === item.title) {
        newProducts.splice(index, 1);
        exists = true;
      }
    });
    if (!exists) {
      newProducts.push(item.title);
    }
    setProducts([...newProducts]);
  };

  useEffect(() => {
    if (products.length > 1) {
      if (products.indexOf("Wire Transfer Services") > -1) {
        setIsWire(true);
        setIsCc(true);
      } else {
        setIsWire(false);
        setIsCc(true);
      }
    } else if (products.length === 1) {
      if (products.indexOf("Wire Transfer Services") > -1) {
        setIsWire(true);
        setIsCc(false);
      } else {
        setIsWire(false);
        setIsCc(true);
      }
    } else {
      setIsWire(false);
      setIsCc(false);
    }
  }, [products]);

  const addMqr = async () => {
    const newProducts = products.map((product) => product);
    const payload = {
      user_id: user?.id,
      ...state.mqr,
      products: newProducts,
      phoneNumber: state.mqr.phoneNumber.toString(),
      metadata: "Some metadata information.",
      wire: wire,
      cc: cc,
    };

    delete payload.country_code;

    await mutateAsync(payload, {
      onSuccess: (response) => {
        navigate("/account-setup/mqr-thanks");
      },
      onError: (error) => {
        console.log("", error);
        // setShowError(true)
      },
    }).catch((error) => setShowError(true));
  };

  const updateMqr = async () => {
    const newProducts = products.map((product) => product);
    const payload = {
      user_id: user?.id,
      ...state.mqr,
      products: newProducts,
      phoneNumber: state.mqr.phoneNumber.toString(),
      metadata: "Some metadata information.",
      wire,
      cc,
    };
    delete payload.country_code;
    await updateAsyncMqr(payload, {
      onSuccess: (response) => {
        navigate("/account-setup/mqr-thanks");
      },
      onError: (error) => {
        console.log("", error);
      },
    }).catch((error) => setShowError(true));
  };
  useEffect(() => {
    if (products.length === 0) {
      setIsDisebled(true);
    } else {
      if (isWire && !isCc && wire) {
        setIsDisebled(false);
      } else if (!isWire && isCc && cc) {
        setIsDisebled(false);
      } else if (isWire && isCc && cc && wire) {
        setIsDisebled(false);
      } else {
        setIsDisebled(true);
      }
    }
  }, [cc, wire, isCc, isWire]);
  return (
    <div>
      <div className="flex gap-x-4 justify-center lg:justify-normal">
        <div className=" min-h-full items-start pt-36 hidden lg:flex">
          <StepsContainer current_step={2} />
        </div>
        <div className=" flex flex-col items-center">
          <div className="font-bold text-2xl text-neutral-600 my-1 md:my-4 flex justify-center">
            MQR
          </div>
          <div className=" md:mt-6 flex flex-col items-center gap-y-6">
            <div className="font-bold text-4xl text-neutral-900">
              Product Selection
            </div>
            <div className="lg:hidden flex">
              <StepsContainer current_step={2} />
            </div>
            <div className=" text-neutral-600 font-medium text-base">
              Tell us what services you are interested in
            </div>
            <ProductSelectionsContainer
              added_products={state?.added_mqr?.products}
              items={product_selections}
              onHandleClick={(item: any) => manageProducts(item)}
            />
            {isWire && (
              <div className="mt-1">
                <div className=" text-neutral-900 mb-1 text-lg font-medium ">
                  Wire transfer services
                </div>
                <MerchantInformationForm
                  onHandleSubmit={(obj: any) => setWire(obj)}
                  added_payload={state?.added_mqr?.wire}
                />
              </div>
            )}

            {isCc && (
              <div className="mt-6">
                <div className=" text-neutral-900 mb-1 text-lg font-medium ">
                  Card Processing Services
                </div>
                <MerchantInformationForm
                  added_payload={state?.added_mqr?.cc}
                  onHandleSubmit={(obj: any) => setCc(obj)}
                />
              </div>
            )}

            {state?.added_mqr ? (
              <FormButton
                type={"button"}
                title="Update"
                isLoding={isPendingUpdate}
                disabled={isPendingUpdate}
                onClick={updateMqr}
              />
            ) : (
              <FormButton
                type={"button"}
                title="Confirm"
                isLoding={isPending}
                disabled={isDisabled || isPending}
                onClick={onSubmit}
              />
            )}
          </div>
        </div>
      </div>
      {showError && (
        <PopUpContainer
          onCancel={() => setShowError(false)}
          onSubmit={() => setShowError(false)}
          submit_title="Got it"
        >
          <>
            <div className="font-bold text-neutral-900 text-lg">
              Somthing went wrong
            </div>
            <div className=" text-sm text-neutral-600 font-semibold my-1 ">
              {"Try check all the required fields and add at least one product"}
            </div>
          </>
        </PopUpContainer>
      )}
    </div>
  );
};
