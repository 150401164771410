export const countries_code = [
  {
    id: 1,
    value: "+93",
    label: 93,
  },
  {
    id: 2,
    value: "+27",
    label: 27,
  },
  {
    id: 3,
    value: "+355",
    label: 355,
  },
  {
    id: 4,
    value: "+49",
    label: 49,
  },
  {
    id: 5,
    value: "+376",
    label: 376,
  },
  {
    id: 6,
    value: "+244",
    label: 244,
  },
  {
    id: 7,
    value: "+1264",
    label: 1264,
  },
  {
    id: 8,
    value: "+672",
    label: 672,
  },
  {
    id: 9,
    value: "+1268",
    label: 1268,
  },
  {
    id: 11,
    value: "+966",
    label: 966,
  },
  {
    id: 12,
    value: "+213",
    label: 213,
  },
  {
    id: 13,
    value: "+54",
    label: 54,
  },
  {
    id: 14,
    value: "+374",
    label: 374,
  },
  {
    id: 15,
    value: "+297",
    label: 297,
  },
  {
    id: 16,
    value: "+61",
    label: 61,
  },
  {
    id: 17,
    value: "+43",
    label: 43,
  },
  {
    id: 18,
    value: "+994",
    label: 994,
  },
  {
    id: 19,
    value: "+1242",
    label: 1242,
  },
  {
    id: 20,
    value: "+973",
    label: 973,
  },
  {
    id: 21,
    value: "+880",
    label: 880,
  },
  {
    id: 22,
    value: "+1246",
    label: 1246,
  },
  {
    id: 23,
    value: "+32",
    label: 32,
  },
  {
    id: 24,
    value: "+501",
    label: 501,
  },
  {
    id: 25,
    value: "+229",
    label: 229,
  },
  {
    id: 26,
    value: "+1441",
    label: 1441,
  },
  {
    id: 27,
    value: "+375",
    label: 375,
  },
  {
    id: 28,
    value: "+591",
    label: 591,
  },
  {
    id: 29,
    value: "+387",
    label: 387,
  },
  {
    id: 30,
    value: "+267",
    label: 267,
  },
  {
    id: 31,
    value: "+55",
    label: 55,
  },
  {
    id: 32,
    value: "+673",
    label: 673,
  },
  {
    id: 33,
    value: "+359",
    label: 359,
  },
  {
    id: 34,
    value: "+226",
    label: 226,
  },
  {
    id: 35,
    value: "+257",
    label: 257,
  },
  {
    id: 36,
    value: "+975",
    label: 975,
  },
  {
    id: 37,
    value: "+238",
    label: 238,
  },
  {
    id: 38,
    value: "+237",
    label: 237,
  },
  {
    id: 39,
    value: "+855",
    label: 855,
  },
  {
    id: 40,
    value: "+1",
    label: 1,
  },
  {
    id: 41,
    value: "+974",
    label: 974,
  },
  {
    id: 42,
    value: "+7",
    label: 7,
  },
  {
    id: 43,
    value: "+235",
    label: 235,
  },
  {
    id: 44,
    value: "+56",
    label: 56,
  },
  {
    id: 45,
    value: "+86",
    label: 86,
  },
  {
    id: 46,
    value: "+357",
    label: 357,
  },
  {
    id: 47,
    value: "+65",
    label: 65,
  },
  {
    id: 48,
    value: "+57",
    label: 57,
  },
  {
    id: 49,
    value: "+269",
    label: 269,
  },
  {
    id: 50,
    value: "+242",
    label: 242,
  },
  {
    id: 51,
    value: "+850",
    label: 850,
  },
  {
    id: 52,
    value: "+82",
    label: 82,
  },
  {
    id: 53,
    value: "+225",
    label: 225,
  },
  {
    id: 54,
    value: "+506",
    label: 506,
  },
  {
    id: 55,
    value: "+385",
    label: 385,
  },
  {
    id: 56,
    value: "+53",
    label: 53,
  },
  {
    id: 57,
    value: "+599",
    label: 599,
  },
  {
    id: 58,
    value: "+45",
    label: 45,
  },
  {
    id: 59,
    value: "+253",
    label: 253,
  },
  {
    id: 60,
    value: "+1767",
    label: 1767,
  },
  {
    id: 61,
    value: "+20",
    label: 20,
  },
  {
    id: 62,
    value: "+503",
    label: 503,
  },
  {
    id: 63,
    value: "+971",
    label: 971,
  },
  {
    id: 64,
    value: "+593",
    label: 593,
  },
  {
    id: 65,
    value: "+291",
    label: 291,
  },
  {
    id: 66,
    value: "+421",
    label: 421,
  },
  {
    id: 67,
    value: "+386",
    label: 386,
  },
  {
    id: 68,
    value: "+34",
    label: 34,
  },
  {
    id: 69,
    value: "+1",
    label: 1,
  },
  {
    id: 70,
    value: "+372",
    label: 372,
  },
  {
    id: 71,
    value: "+251",
    label: 251,
  },
  {
    id: 72,
    value: "+679",
    label: 679,
  },
  {
    id: 73,
    value: "+63",
    label: 63,
  },
  {
    id: 74,
    value: "+358",
    label: 358,
  },
  {
    id: 75,
    value: "+33",
    label: 33,
  },
  {
    id: 76,
    value: "+241",
    label: 241,
  },
  {
    id: 77,
    value: "+220",
    label: 220,
  },
  {
    id: 78,
    value: "+233",
    label: 233,
  },
  {
    id: 79,
    value: "+995",
    label: 995,
  },
  {
    id: 80,
    value: "+350",
    label: 350,
  },
  {
    id: 81,
    value: "+1473",
    label: 1473,
  },
  {
    id: 82,
    value: "+30",
    label: 30,
  },
  {
    id: 83,
    value: "+299",
    label: 299,
  },
  {
    id: 84,
    value: "+590",
    label: 590,
  },
  {
    id: 85,
    value: "+1671",
    label: 1671,
  },
  {
    id: 86,
    value: "+502",
    label: 502,
  },
  {
    id: 87,
    value: "+44",
    label: 44,
  },
  {
    id: 88,
    value: "+592",
    label: 592,
  },
  {
    id: 89,
    value: "+594",
    label: 594,
  },
  {
    id: 90,
    value: "+224",
    label: 224,
  },
  {
    id: 91,
    value: "+240",
    label: 240,
  },
  {
    id: 92,
    value: "+245",
    label: 245,
  },
  {
    id: 93,
    value: "+509",
    label: 509,
  },
  {
    id: 94,
    value: "+31",
    label: 31,
  },
  {
    id: 95,
    value: "+504",
    label: 504,
  },
  {
    id: 96,
    value: "+852",
    label: 852,
  },
  {
    id: 97,
    value: "+36",
    label: 36,
  },
  {
    id: 98,
    value: "+967",
    label: 967,
  },
  {
    id: 99,
    value: "+55",
    label: 55,
  },
  {
    id: 100,
    value: "+262",
    label: 262,
  },
  {
    id: 101,
    value: "+44",
    label: 44,
  },
  {
    id: 102,
    value: "+590",
    label: 590,
  },
  {
    id: 103,
    value: "+0",
    label: 0,
  },
  {
    id: 104,
    value: "+61",
    label: 61,
  },
  {
    id: 105,
    value: "+672",
    label: 672,
  },
  {
    id: 106,
    value: "+358",
    label: 358,
  },
  {
    id: 107,
    value: "+1345",
    label: 1345,
  },
  {
    id: 108,
    value: "+672",
    label: 672,
  },
  {
    id: 109,
    value: "+682",
    label: 682,
  },
  {
    id: 110,
    value: "+500",
    label: 500,
  },
  {
    id: 111,
    value: "+298",
    label: 298,
  },
  {
    id: 112,
    value: "+500",
    label: 500,
  },
  {
    id: 113,
    value: "+1670",
    label: 1670,
  },
  {
    id: 114,
    value: "+692",
    label: 692,
  },
  {
    id: 115,
    value: "+246",
    label: 246,
  },
  {
    id: 116,
    value: "+64",
    label: 64,
  },
  {
    id: 117,
    value: "+677",
    label: 677,
  },
  {
    id: 118,
    value: "+1649",
    label: 1649,
  },
  {
    id: 119,
    value: "+1340",
    label: 1340,
  },
  {
    id: 120,
    value: "+1284",
    label: 1284,
  },
  {
    id: 121,
    value: "+91",
    label: 91,
  },
  {
    id: 122,
    value: "+62",
    label: 62,
  },
  {
    id: 123,
    value: "+98",
    label: 98,
  },
  {
    id: 124,
    value: "+964",
    label: 964,
  },
  {
    id: 125,
    value: "+353",
    label: 353,
  },
  {
    id: 126,
    value: "+354",
    label: 354,
  },
  {
    id: 127,
    value: "+972",
    label: 972,
  },
  {
    id: 128,
    value: "+39",
    label: 39,
  },
  {
    id: 129,
    value: "+1876",
    label: 1876,
  },
  {
    id: 130,
    value: "+81",
    label: 81,
  },
  {
    id: 131,
    value: "+44",
    label: 44,
  },
  {
    id: 132,
    value: "+962",
    label: 962,
  },
  {
    id: 133,
    value: "+686",
    label: 686,
  },
  {
    id: 134,
    value: "+383",
    label: 383,
  },
  {
    id: 135,
    value: "+965",
    label: 965,
  },
  {
    id: 136,
    value: "+856",
    label: 856,
  },
  {
    id: 137,
    value: "+266",
    label: 266,
  },
  {
    id: 138,
    value: "+371",
    label: 371,
  },
  {
    id: 139,
    value: "+961",
    label: 961,
  },
  {
    id: 140,
    value: "+231",
    label: 231,
  },
  {
    id: 141,
    value: "+218",
    label: 218,
  },
  {
    id: 142,
    value: "+423",
    label: 423,
  },
  {
    id: 143,
    value: "+370",
    label: 370,
  },
  {
    id: 144,
    value: "+352",
    label: 352,
  },
  {
    id: 145,
    value: "+853",
    label: 853,
  },
  {
    id: 146,
    value: "+389",
    label: 389,
  },
  {
    id: 147,
    value: "+261",
    label: 261,
  },
  {
    id: 148,
    value: "+60",
    label: 60,
  },
  {
    id: 149,
    value: "+265",
    label: 265,
  },
  {
    id: 150,
    value: "+960",
    label: 960,
  },
  {
    id: 151,
    value: "+223",
    label: 223,
  },
  {
    id: 152,
    value: "+356",
    label: 356,
  },
  {
    id: 153,
    value: "+212",
    label: 212,
  },
  {
    id: 154,
    value: "+596",
    label: 596,
  },
  {
    id: 155,
    value: "+230",
    label: 230,
  },
  {
    id: 156,
    value: "+222",
    label: 222,
  },
  {
    id: 157,
    value: "+269",
    label: 269,
  },
  {
    id: 158,
    value: "+52",
    label: 52,
  },
  {
    id: 159,
    value: "+691",
    label: 691,
  },
  {
    id: 160,
    value: "+258",
    label: 258,
  },
  {
    id: 161,
    value: "+373",
    label: 373,
  },
  {
    id: 162,
    value: "+377",
    label: 377,
  },
  {
    id: 163,
    value: "+976",
    label: 976,
  },
  {
    id: 164,
    value: "+382",
    label: 382,
  },
  {
    id: 165,
    value: "+1664",
    label: 1664,
  },
  {
    id: 166,
    value: "+95",
    label: 95,
  },
  {
    id: 167,
    value: "+264",
    label: 264,
  },
  {
    id: 168,
    value: "+674",
    label: 674,
  },
  {
    id: 169,
    value: "+977",
    label: 977,
  },
  {
    id: 170,
    value: "+505",
    label: 505,
  },
  {
    id: 171,
    value: "+227",
    label: 227,
  },
  {
    id: 172,
    value: "+234",
    label: 234,
  },
  {
    id: 173,
    value: "+683",
    label: 683,
  },
  {
    id: 174,
    value: "+47",
    label: 47,
  },
  {
    id: 175,
    value: "+687",
    label: 687,
  },
  {
    id: 176,
    value: "+64",
    label: 64,
  },
  {
    id: 177,
    value: "+968",
    label: 968,
  },
  {
    id: 178,
    value: "+680",
    label: 680,
  },
  {
    id: 179,
    value: "+507",
    label: 507,
  },
  {
    id: 180,
    value: "+675",
    label: 675,
  },
  {
    id: 181,
    value: "+92",
    label: 92,
  },
  {
    id: 182,
    value: "+595",
    label: 595,
  },
  {
    id: 183,
    value: "+51",
    label: 51,
  },
  {
    id: 184,
    value: "+689",
    label: 689,
  },
  {
    id: 185,
    value: "+48",
    label: 48,
  },
  {
    id: 186,
    value: "+1787",
    label: 1787,
  },
  {
    id: 187,
    value: "+351",
    label: 351,
  },
  {
    id: 188,
    value: "+254",
    label: 254,
  },
  {
    id: 189,
    value: "+996",
    label: 996,
  },
  {
    id: 190,
    value: "+44",
    label: 44,
  },
  {
    id: 191,
    value: "+236",
    label: 236,
  },
  {
    id: 192,
    value: "+242",
    label: 242,
  },
  {
    id: 193,
    value: "+1809",
    label: 1809,
  },
  {
    id: 194,
    value: "+420",
    label: 420,
  },
  {
    id: 195,
    value: "+40",
    label: 40,
  },
  {
    id: 196,
    value: "+250",
    label: 250,
  },
  {
    id: 197,
    value: "+70",
    label: 70,
  },
  {
    id: 198,
    value: "+212",
    label: 212,
  },
  {
    id: 199,
    value: "+599",
    label: 599,
  },
  {
    id: 200,
    value: "+684",
    label: 684,
  },
  {
    id: 201,
    value: "+1684",
    label: 1684,
  },
  {
    id: 202,
    value: "+378",
    label: 378,
  },
  {
    id: 203,
    value: "+290",
    label: 290,
  },
  {
    id: 204,
    value: "+1758",
    label: 1758,
  },
  {
    id: 205,
    value: "+590",
    label: 590,
  },
  {
    id: 206,
    value: "+1869",
    label: 1869,
  },
  {
    id: 207,
    value: "+1",
    label: 1,
  },
  {
    id: 208,
    value: "+508",
    label: 508,
  },
  {
    id: 209,
    value: "+239",
    label: 239,
  },
  {
    id: 210,
    value: "+1784",
    label: 1784,
  },
  {
    id: 211,
    value: "+221",
    label: 221,
  },
  {
    id: 212,
    value: "+232",
    label: 232,
  },
  {
    id: 213,
    value: "+381",
    label: 381,
  },
  {
    id: 215,
    value: "+248",
    label: 248,
  },
  {
    id: 216,
    value: "+963",
    label: 963,
  },
  {
    id: 217,
    value: "+252",
    label: 252,
  },
  {
    id: 218,
    value: "+94",
    label: 94,
  },
  {
    id: 219,
    value: "+268",
    label: 268,
  },
  {
    id: 220,
    value: "+249",
    label: 249,
  },
  {
    id: 221,
    value: "+211",
    label: 211,
  },
  {
    id: 222,
    value: "+46",
    label: 46,
  },
  {
    id: 223,
    value: "+41",
    label: 41,
  },
  {
    id: 224,
    value: "+597",
    label: 597,
  },
  {
    id: 225,
    value: "+47",
    label: 47,
  },
  {
    id: 226,
    value: "+66",
    label: 66,
  },
  {
    id: 227,
    value: "+886",
    label: 886,
  },
  {
    id: 228,
    value: "+992",
    label: 992,
  },
  {
    id: 229,
    value: "+255",
    label: 255,
  },
  {
    id: 230,
    value: "+262",
    label: 262,
  },
  {
    id: 231,
    value: "+246",
    label: 246,
  },
  {
    id: 232,
    value: "+970",
    label: 970,
  },
  {
    id: 233,
    value: "+670",
    label: 670,
  },
  {
    id: 234,
    value: "+228",
    label: 228,
  },
  {
    id: 235,
    value: "+676",
    label: 676,
  },
  {
    id: 236,
    value: "+690",
    label: 690,
  },
  {
    id: 237,
    value: "+1868",
    label: 1868,
  },
  {
    id: 238,
    value: "+216",
    label: 216,
  },
  {
    id: 239,
    value: "+7370",
    label: 7370,
  },
  {
    id: 240,
    value: "+90",
    label: 90,
  },
  {
    id: 241,
    value: "+688",
    label: 688,
  },
  {
    id: 242,
    value: "+380",
    label: 380,
  },
  {
    id: 243,
    value: "+256",
    label: 256,
  },
  {
    id: 244,
    value: "+598",
    label: 598,
  },
  {
    id: 245,
    value: "+998",
    label: 998,
  },
  {
    id: 246,
    value: "+678",
    label: 678,
  },
  {
    id: 247,
    value: "+39",
    label: 39,
  },
  {
    id: 248,
    value: "+58",
    label: 58,
  },
  {
    id: 249,
    value: "+84",
    label: 84,
  },
  {
    id: 250,
    value: "+681",
    label: 681,
  },
  {
    id: 251,
    value: "+260",
    label: 260,
  },
  {
    id: 252,
    value: "+263",
    label: 263,
  },
];
