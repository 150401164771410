import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";

export function useForgotPassword() {
  return useMutation(
    // (data: any) => client.post("login", data, { timeout: 5000 }),
    {
      mutationFn: (data: any) =>
        client.post("forgot-password", data, { timeout: 5000 }),
      onError: (error: any) => {
        console.log(error);
      },
      onSuccess(response: any) {},
    }
  );
}
