import { useMutation } from "@tanstack/react-query";
import { client } from "../../global/apiClient";
import { getItem } from "../../../core/storage";

export function useCreateMqr() {
  return useMutation(
    // (data: any) => client.post("login", data, { timeout: 5000 }),
    {
      mutationFn: (data: any) =>
        client.post("merchant/mqr/create", data, {
          timeout: 5000,
          headers: {
            Authorization: `Bearer ${getItem("auth")}`,
            "Access-Control-Allow-Origin": "*",
          },
        }),
      onError: (error: any) => {
        console.log(error);
      },
      onSuccess(response: any) {
        console.log("response", response);
      },
    }
  );
}
