import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const AuthHeader = () => {
  let location = useLocation();

  return (
    <div className=" flex w-full justify-between items-center xs:px-4">
      <div className=" flex items-center gap-x-2">
        <img src="/logo/Logomark.png" alt="" />
        <div className="font-bold text-neutral-900 text-lg xs:text-xl ">Freedom PSP</div>
      </div>
      <div className="flex items-center gap-x-3 text-sm">
        {location.pathname.includes("register") ? (
          <>
            <div className=" text-neutral-600 hidden xs:flex">Already have an account?</div>
            <div className=" text-[#1E96EB]">
              <Link to={"/login"}>Log in</Link>
            </div>
          </>
        ) : (
          <>
            <div className=" text-neutral-600 hidden xs:flex">Don’t have an account</div>
            <div className=" text-[#1E96EB]">
              <Link to={"/register"}>Create account</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
