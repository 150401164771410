import { ProductSelectionItem } from "../product-selection-item";
export const ProductSelectionsContainer = ({
  items,
  onHandleClick,
  added_products,
}: {
  items: any;
  onHandleClick: any;
  added_products?: Array<string>;
}) => {
  return (
    <div className="flex justify-center flex-wrap gap-2 max-w-[400px]">
      {items.map((item: any) => (
        <ProductSelectionItem
          isSelected={
            added_products ? added_products.indexOf(item.title) > -1 : false
          }
          item={item}
          key={item.id}
          onHandleClick={(item: any) => onHandleClick(item)}
        />
      ))}
    </div>
  );
};
