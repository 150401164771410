export const DocumentRequiredContainer = ({
  docs,
  current_step,
  ifExists,
  ifRejected,
  ifApproved,
}: {
  docs: Array<{ title: string; id: number; type: string }>;
  current_step: number;
  ifExists: any;
  ifRejected: any;
  ifApproved: any;
}) => {
  return (
    <div className=" bg-neutral-100 w-full rounded-lg py-4 px-3 flex flex-col gap-y-2">
      <div className=" text-neutral-900 text-base font-semibold">
        Required documents
      </div>
      {docs.map((doc: any, index: number) => (
        <div className=" flex items-center gap-x-2" key={doc.id}>
          <div
            className={` ${
              !ifExists(doc.type)
                ? "bg-neutral-300"
                : ifRejected(doc)
                ? " bg-red-600"
                : ifApproved(doc)
                ? " bg-green-600"
                : " bg-secondary-500"
            } flex  rounded-full w-6 h-6 items-center justify-center text-white text-sm`}
          >
            {index + 1}
          </div>
          <div className="font-semibold text-neutral-600 text-xs xs:text-sm">
            {doc.title}
          </div>
        </div>
      ))}
    </div>
  );
};
